import React, { useEffect, useState } from "react";
import { RRule, Weekday } from "rrule";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useTranslate } from "react-admin";

interface IEventDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (eventData: any, eventId: string) => void;
  markTimeSlotAsRemoved: (eventId: string) => void;
  onDelete: (id: string) => void;
  initialStart: Date;
  initialEnd: Date;
  event?: any;
}

const recurrenceOptions = [
  { value: "none", label: "app.delivery_zone.ramps.does_not_repeat" },
  { value: "daily", label: "app.delivery_zone.ramps.daily" },
  { value: "weekly", label: "app.delivery_zone.ramps.weekly" },
];

const weekDays = [
  { value: RRule.MO, label: "app.delivery_zone.ramps.monday" },
  { value: RRule.TU, label: "app.delivery_zone.ramps.tuesday" },
  { value: RRule.WE, label: "app.delivery_zone.ramps.wednesday" },
  { value: RRule.TH, label: "app.delivery_zone.ramps.thursday" },
  { value: RRule.FR, label: "app.delivery_zone.ramps.friday" },
  { value: RRule.SA, label: "app.delivery_zone.ramps.saturday" },
  { value: RRule.SU, label: "app.delivery_zone.ramps.sunday" },
];

const EventDialog: React.FC<IEventDialogProps> = ({
  open,
  onClose,
  onSave,
  onDelete,
  initialStart,
  initialEnd,
  event,
  markTimeSlotAsRemoved,
}) => {
  const [recurrence, setRecurrence] = useState<string>("none");
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<string>("");
  const [selectedWeekDays, setSelectWeekDays] = useState<Weekday[]>([]);
  const [activeStatus, setActiveStatus] = useState<string>("active");

  const translate = useTranslate();

  const today = new Date();
  const todayDateString = today.toISOString().split("T")[0];

  useEffect(() => {
    if (event) {
      setRecurrence("none");
      setRecurrenceEndDate("");
      setSelectWeekDays([]);
      setActiveStatus(event.extendedProps.active || "active");

      if (event.rrule) {
        const rruleOptions =
          typeof event.rrule === "string"
            ? RRule.fromString(event.rrule).options
            : event.rrule;

        if (rruleOptions.freq !== undefined) {
          setRecurrence(RRule.FREQUENCIES[rruleOptions.freq].toLowerCase());
        }

        if (rruleOptions.until) {
          setRecurrenceEndDate(moment(rruleOptions.until).format("YYYY-MM-DD"));
        }

        if (rruleOptions.byweekday) {
          const weekdays = rruleOptions.byweekday.map(
            (weekday: any) => weekday.weekday,
          );
          setSelectWeekDays(weekdays);
        }
      }
    } else {
      setRecurrence("none");
      setRecurrenceEndDate("");
      setSelectWeekDays([]);
      setActiveStatus("active");
    }
  }, [event]);

  const handleSave = (): void => {
    if (!recurrenceEndDate && recurrence !== "none") {
      alert(translate("app.delivery_zone.ramps.recurrence_end_date_required"));
      return;
    }

    const eventData: any = {
      start: initialStart,
      end: initialEnd,
      active: activeStatus || "active",
      extendedProps: {
        active: activeStatus || "active",
      },
    };

    if (recurrence !== "none") {
      eventData.rrule = {
        freq: RRule[recurrence.toUpperCase() as keyof typeof RRule] || 0,
        dtstart: initialStart,
      };

      eventData.rrule.until = moment(recurrenceEndDate).endOf("day");

      if (recurrence === "weekly" && selectedWeekDays.length > 0) {
        eventData.rrule.byweekday = selectedWeekDays;
      }

      if (recurrence === "daily") {
        eventData.rrule.byweekday = weekDays.map((day) => day.value);
      }
    }

    onSave(eventData, event?.id);
  };

  const handleDelete = () => {
    if (event && event.id) {
      onDelete(event.id);
      markTimeSlotAsRemoved(event.id);
    }
  };

  const handleRecurrenceChange = (e: SelectChangeEvent<string>): void => {
    setRecurrence(e.target.value as string);
  };

  const handleWeekDayChange = (dayValue: Weekday) => {
    setSelectWeekDays((prevDays) =>
      prevDays.includes(dayValue)
        ? prevDays.filter((day) => day !== dayValue)
        : [...prevDays, dayValue],
    );
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {event
          ? translate("app.delivery_zone.ramps.edit_timeslot")
          : translate("app.delivery_zone.ramps.create_timeslot")}
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={activeStatus === "active"}
              onChange={(e) =>
                setActiveStatus(e.target.checked ? "active" : "inactive")
              }
              name="activeStatus"
              color="primary"
            />
          }
          label={translate("app.delivery_zone.ramps.timeslot.active")}
        />
        {!event && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel id="recurrence-label">
                {translate("app.delivery_zone.ramps.recurrence")}
              </InputLabel>
              <Select
                labelId="recurrence-label"
                value={recurrence}
                onChange={handleRecurrenceChange}
                label={translate("app.delivery_zone.ramps.recurrence")}
              >
                {recurrenceOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {translate(option.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {recurrence !== "none" && (
              <TextField
                label={translate("app.delivery_zone.ramps.recurrence_end_date")}
                type="date"
                value={recurrenceEndDate}
                onChange={(e) => setRecurrenceEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: todayDateString,
                }}
                fullWidth
                margin="normal"
              />
            )}

            {recurrence === "weekly" && (
              <FormControl component="fieldset" margin="normal">
                <div>
                  {weekDays.map((day) => (
                    <FormControlLabel
                      key={day.label}
                      control={
                        <Checkbox
                          checked={selectedWeekDays.includes(day.value)}
                          onChange={() => handleWeekDayChange(day.value)}
                        />
                      }
                      label={translate(day.label)}
                    />
                  ))}
                </div>
              </FormControl>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {event && (
          <Button onClick={handleDelete}>
            {translate("app.delivery_zone.ramps.delete")}
          </Button>
        )}
        <Button onClick={onClose}>
          {translate("app.delivery_zone.ramps.close")}
        </Button>
        <Button onClick={handleSave}>
          {translate("app.delivery_zone.ramps.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
