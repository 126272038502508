import React from "react";
import { SelectInput, useGetList } from "react-admin";
import { CircularProgress } from "@mui/material";
import { StyledSelect } from "../../themes/styles";

interface IGroupSelectInputProps {
  source: string;
  label?: string;
  isRequired?: boolean;
}

const GroupSelectInput = ({
  source,
  label,
  isRequired = false,
}: IGroupSelectInputProps) => {
  const { data, isLoading } = useGetList("organization/admin/group/tenants", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "createdAt", order: "DESC" },
  });

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return (
    <StyledSelect
      source={source}
      variant="outlined"
      choices={data}
      optionText="displayName"
      optionValue="id"
      label={label || "app.administration.tenants.tenant"}
      validate={
        isRequired
          ? (value) => (value ? undefined : "app.validation.required")
          : undefined
      }
    />
  );
};

export default GroupSelectInput;
