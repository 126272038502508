import * as React from "react";
import { ComponentType, useEffect, isValidElement, createElement } from "react";
import { Routes, Route } from "react-router-dom";
import {
  AdminChildren,
  CatchAllComponent,
  CoreAdminRoutes,
  CoreLayoutProps,
  DashboardComponent,
  LayoutComponent,
  LoadingComponent,
  LoginComponent,
  Ready,
  TitleComponent,
} from "ra-core";
import OrganizationLandingPage from "./components/OrganizationLandingPage";

const DefaultLayout = ({ children }: CoreLayoutProps) => <>{children}</>;

export interface CoreAdminUIProps {
  catchAll?: CatchAllComponent;

  children?: AdminChildren;

  dashboard?: DashboardComponent;

  disableTelemetry?: boolean;

  layout?: LayoutComponent;

  loading?: LoadingComponent;

  authCallbackPage?: ComponentType | boolean;

  loginPage?: LoginComponent | boolean;

  menu?: ComponentType;

  requireAuth?: boolean;

  ready?: ComponentType;

  title?: TitleComponent;
}

export const CustomCoreAdminUI = (props: CoreAdminUIProps) => {
  const {
    catchAll = Noop,
    children,
    dashboard,
    disableTelemetry = false,
    layout = DefaultLayout,
    loading = Noop,
    loginPage: LoginPage = false,
    authCallbackPage: LoginCallbackPage = false,
    menu, // deprecated, use a custom layout instead
    ready = Ready,
    title = "React Admin",
    requireAuth = false,
  } = props;

  useEffect(() => {
    if (
      disableTelemetry ||
      process.env.NODE_ENV !== "production" ||
      typeof window === "undefined" ||
      typeof window.location === "undefined" ||
      typeof Image === "undefined"
    ) {
      return;
    }
    const img = new Image();
    img.src = `https://react-admin-telemetry.marmelab.com/react-admin-telemetry?domain=${window.location.hostname}`;
  }, [disableTelemetry]);

  return (
    <Routes>
      {LoginPage !== false && LoginPage !== true ? (
        <Route
          path="/login/:organizationName"
          element={createOrGetElement(LoginPage)}
        />
      ) : null}
      <Route path="/login" element={<OrganizationLandingPage />} />
      {LoginCallbackPage !== false && LoginCallbackPage !== true ? (
        <Route
          path="/auth-callback"
          element={createOrGetElement(LoginCallbackPage)}
        />
      ) : null}

      <Route
        path="/*"
        element={
          <CoreAdminRoutes
            catchAll={catchAll}
            dashboard={dashboard}
            layout={layout}
            loading={loading}
            menu={menu}
            requireAuth={requireAuth}
            ready={ready}
            title={title}
          >
            {children}
          </CoreAdminRoutes>
        }
      />
    </Routes>
  );
};

const createOrGetElement = (el: any) =>
  isValidElement(el) ? el : createElement(el);

const Noop = () => null;
