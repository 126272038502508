import axios from "axios";
import { ConfigManager } from "../constants/ConfigManager";
import { IConfigResponse } from "../types/interfaces/config-response.interface";

export const fetchConfig = async () => {
  try {
    const config = await axios.get("/config.json");
    return config.data;
  } catch (e) {
    console.log(e);
  }
};

export const setConfig = async () => {
  try {
    const config: IConfigResponse = await fetchConfig();
    ConfigManager.getInstance().setParkingName(config.customer_parking_name);
    ConfigManager.getInstance().setStripeKey(config.stripe_key);
    ConfigManager.getInstance().setApiUrl(config.api_url);
    ConfigManager.getInstance().setApiAdminUrl(config.api_admin_url);
  } catch (e) {
    console.log(e);
  }
};
