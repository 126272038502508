export enum EAllowedAttributeType {
  SingleLineText = "single-line-text",
  MultiLineText = "multi-line-text",
  Email = "email",
  Phone = "phone",
  PlateNumber = "plate-number",
  Length = "length",
  Mass = "mass",
  Integer = "integer",
  Decimal = "decimal",
  DateTime = "date-time",
  Duration = "duration",
  Boolean = "boolean",
  SingleSelect = "single-select",
  Status = "status",
}
