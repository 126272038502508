import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../../constants/ConfigManager";
import { apiRequest } from "../client";
import { ITimeslot } from "../../types/interfaces/delivery-zone/timeslot.interface";

type TAttribute = {
  id: string;
  value: string | number | string[];
};

interface ICreateRamp {
  attributes: TAttribute[];
}

interface IUpdateRamp {
  active: string;
  timeslots: ITimeslot[];
}

const createRamp = async <T>(data: ICreateRamp) => {
  const orgId = localStorage.getItem("orgId");

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/delivery/admin/${orgId}/ramps`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const updateRamp = async <T>(data: IUpdateRamp, rampId: string) => {
  const orgId = localStorage.getItem("orgId");

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/delivery/admin/${orgId}/ramps/${rampId}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const deleteRamp = async <T>(rampId: string) => {
  const orgId = localStorage.getItem("orgId");

  try {
    const config: AxiosRequestConfig = {
      method: "DELETE",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/delivery/admin/${orgId}/ramps/${rampId}`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const rampService = {
  createRamp,
  updateRamp,
  deleteRamp,
};
