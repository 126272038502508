import React from "react";
import { InputProps, Validator, regex } from "react-admin";
import { StyledTextInput } from "../../themes/styles";

interface IPhoneInputProps extends InputProps {
  validate: Validator[];
}

const PhoneInput: React.FC<IPhoneInputProps> = (props) => {
  const { validate, ...rest } = props;
  const polishNumberRegex = /^(\+48\s?)?(\d{3}[-\s]?\d{3}[-\s]?\d{3})$/;
  const validators = [regex(polishNumberRegex), ...(validate || [])];

  return <StyledTextInput {...rest} validate={validators} />;
};

export default PhoneInput;
