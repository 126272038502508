import React from "react";
import { CommonInputProps, required, Validator } from "react-admin";
import { EAllowedAttributeType } from "../../../../types/enums/ticket-attribute-type.enums";
import { ITicketAttributeOption } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import { StyledTextInput } from "../../../../themes/styles";
import { rampInputTypeComponentMap } from "../../../../utils/attributes/delivery/rampInputTypeComponentMap";
import { helpdeskInputTypeComponentMap } from "../../../../utils/attributes/helpdesk/helpdeskInputTypeComponentMap";

export type TAttributeInputProps = {
  id: string;
  source: string;
  label: string;
  inputType: EAllowedAttributeType;
  choices?: ITicketAttributeOption[];
  isRequired: boolean;
  isSavingNewValues?: boolean;
  inputComponentsMapKey?: string;
};

const inputComponentsMaps: any = {
  DeliveryZone_Ramp: rampInputTypeComponentMap,
  Helpdesk: helpdeskInputTypeComponentMap,
};

const AttributeInput = ({
  id,
  source,
  label,
  inputType,
  choices,
  isRequired,
  isSavingNewValues,
  inputComponentsMapKey = "Helpdesk",
}: TAttributeInputProps) => {
  // Get the input components map for given map key
  const inputComponentsMap = inputComponentsMaps[inputComponentsMapKey];
  // Get the input component for the given inputType
  const InputComponent = inputComponentsMap[inputType];

  if (!InputComponent) {
    console.error(`Unknown input type: ${inputType}`);
    return <></>;
  }

  const validators: Validator[] = isRequired ? [required()] : [];
  const commonProps: CommonInputProps = {
    source: `${source}.value`,
    label,
    variant: "outlined",
    defaultValue: "",
    validate: validators,
  };

  return (
    <>
      <StyledTextInput
        source={`${source}.id`}
        style={{ display: "none" }}
        defaultValue={id}
        validate={isRequired ? required() : []}
        hidden
      />
      <InputComponent
        {...commonProps}
        style={{ width: "100%" }}
        choices={choices}
        isRequired={isRequired}
        isSavingNewValues={isSavingNewValues}
      />
    </>
  );
};

export default AttributeInput;
