import React, { useEffect, useMemo, useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  ListToolbarProps,
  TextField,
  TopToolbar,
  useAuthenticated,
  useLocaleState,
  useListContext,
  useList,
  ListContextProvider,
  Empty,
} from "react-admin";
import {
  sanitizeListRestProps,
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from "ra-core";
import { Alert, Chip } from "@mui/material";
import { listTopFilter, StyledList } from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import {
  ITickerUser,
  ITicketAccess,
} from "../../../types/interfaces/helpdesk/ticket/ticket.interface";
import CustomPagination from "../../../components/common/CustomPagination";
import { ITicketAttributeOption } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import { reformatToAcceptableByListComponent } from "../../../utils/ticket/ticketsHelper";
import { helpdeskTicketsService } from "../../../api/HelpdeskTicketsService";
import { Roles } from "../../../types/enums/roles";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";

export interface ITicketGridRow {
  id: string;
  value: string | ITicketAttributeOption | undefined;
  name: string;
  createdAt: any;
  reporter: ITickerUser;
}

const TicketsListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const TicketsListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const TicketsListView = () => {
  const [locale] = useLocaleState();
  const {
    data,
    page,
    perPage,
    sort: parentSort,
    setSort: setParentSort,
  } = useListContext();
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState<ITicketGridRow[]>([]);

  const sort = parentSort || { field: "id", order: "DESC" };

  const listContext = useList({
    data: list,
    sort,
    isLoading,
    page: page,
    perPage: perPage,
  });

  useEffect(() => {
    if (data) {
      setList(reformatToAcceptableByListComponent(data));
      setIsLoading(false);
    }
  }, [data, page, perPage, sort]);

  const handleSort = (field: string, order: "ASC" | "DESC") => {
    setParentSort({ field, order });
  };

  return (
    <SkeletonLoaderList>
      <ListContextProvider
        value={{
          ...listContext,
          setSort: (sort) => {
            handleSort(sort.field, sort.order);
          },
        }}
      >
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField
            source="title"
            label="app.helpdesk.title"
            sortable={false}
          />
          <FunctionField
            label="app.helpdesk.priority"
            color="primary"
            render={(record: any) => {
              if (record?.priority) {
                return (
                  <Chip
                    color="primary"
                    label={record?.priority?.value[locale]}
                    style={{ background: record?.priority?.style?.color }}
                  />
                );
              }
            }}
          />
          <FunctionField
            label="Status"
            color="primary"
            render={(record: any) => {
              if (record?.status) {
                return (
                  <Chip
                    color="primary"
                    label={record?.status?.value[locale]}
                    style={{ background: record?.status?.style?.color }}
                  />
                );
              }
            }}
          />
          <FunctionField
            label="app.helpdesk.reporter"
            render={(record: ITicketGridRow) =>
              record.reporter?.firstName &&
              `${record.reporter.firstName} ${record.reporter.lastName}`
            }
          />
          <DateField
            source="createdAt"
            showTime={true}
            label="app.helpdesk.createdAt"
            sortable={true}
          />
        </Datagrid>
      </ListContextProvider>
    </SkeletonLoaderList>
  );
};

const TicketsList = () => {
  useAuthenticated();
  const redirect = useRedirect();
  const t = useTranslate();
  const notify = useNotify();
  const { permissions, isLoading } = usePermissions<string[]>();
  const [helpdeskTicketsAccess, setHelpdeskTicketsAccess] =
    useState<ITicketAccess | null>(null);
  const canCreate = useMemo(() => {
    return helpdeskTicketsAccess !== null && helpdeskTicketsAccess.canCreate;
  }, [helpdeskTicketsAccess]);

  useEffect(() => {
    if (!isLoading) {
      if (permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN)) {
        helpdeskTicketsService
          .adminGetTicketsAccess<IBasicResponse<ITicketAccess>>()
          .then((response) => {
            setHelpdeskTicketsAccess(response.data);
          });
      } else {
        helpdeskTicketsService
          .getTicketsAccess<IBasicResponse<ITicketAccess>>()
          .then((response) => {
            setHelpdeskTicketsAccess(response.data);
          });
      }
    }
  }, [permissions, isLoading]);

  useEffect(() => {
    if (
      helpdeskTicketsAccess?.canCreate === false &&
      permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN)
    ) {
      notify(
        <Alert
          severity="warning"
          style={{ cursor: "pointer" }}
          onClick={(): void =>
            redirect("/helpdesk/admin/group/workflows/create")
          }
        >
          {t("app.helpdesk.ticketsPage.noWorkflowAvailableAdmin")}
        </Alert>,
        {
          autoHideDuration: 1000 * 8,
        },
      );
    } else if (helpdeskTicketsAccess?.canCreate === false) {
      notify(
        <Alert severity="info">
          {t("app.helpdesk.ticketsPage.noWorkflowAvailable")}
        </Alert>,
      );
    }
  }, [helpdeskTicketsAccess?.canCreate, notify, permissions, redirect, t]);

  return (
    <StyledList
      className="styled-list"
      actions={<TicketsListTopToolbar />}
      pagination={<TicketsListPagination />}
      sort={{ field: "createdAt", order: "DESC" }}
      disableSyncWithLocation={true}
      hasCreate={canCreate}
    >
      <TicketsListView />
    </StyledList>
  );
};

export default TicketsList;
