import React from "react";
import {
  Edit,
  SimpleForm,
  useAuthenticated,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Toolbar, ToolbarProps } from "@mui/material";
import {
  buttons,
  StyledDateInput,
  StyledSaveButton,
} from "../../../themes/styles";

const ReservationsEditToolbar = (props: ToolbarProps) => {
  return (
    <Toolbar
      {...props}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const ReservationsEdit = () => {
  useAuthenticated();

  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify(translate("app.parking.reservation_was_updated"), {
      type: "success",
    });
    redirect("list", "parking/admin/group/reservations");
  };

  const transform = (data: { id: string; from: string; to: string }) => ({
    id: data.id,
    from: data.from.split("T")[0],
    to: data.to.split("T")[0],
  });

  return (
    <Edit
      redirect="list"
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess }}
      transform={transform}
      actions={false}
      sx={{
        "& .MuiButton-text, .MuiButton-root": buttons.whiteButton,
        "& .RaEdit-main": {
          paddingTop: "40px",
        },
      }}
    >
      <SimpleForm
        sx={{ paddingTop: "20px" }}
        toolbar={<ReservationsEditToolbar />}
      >
        <StyledDateInput
          label="app.parking.reservationFrom"
          source="from"
          variant="outlined"
          sx={{ width: "200px", marginTop: "20px" }}
        />

        <StyledDateInput
          label="app.parking.reservationTo"
          source="to"
          variant="outlined"
          sx={{ width: "200px", marginTop: "20px" }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ReservationsEdit;
