import React, { useEffect, useState } from "react";
import {
  Create,
  ListToolbarProps,
  SimpleForm,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { CircularProgress, Toolbar } from "@mui/material";
import {
  FormInnerContainer,
  FormTitle,
  LoaderContainer,
} from "../../helpdesk/tickets/TicketsCreate";
import { ITicketAttribute } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { EResource, getResourceUrl } from "../../../utils/resourcesHelper";
import AttributesInputArray from "../../../components/resources/helpdesk/tickets/AttributesInputArray";
import { rampAttributesService } from "../../../api/deliveryZone/RampAttributesService";
import { rampService } from "../../../api/deliveryZone/RampService";
import { StyledSaveButton } from "../../../themes/styles";

const RampsCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const RampsCreate = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const [attributes, setAttributes] = useState<ITicketAttribute[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const rampsAttributeUrl = getResourceUrl(EResource.RAMPS_ATTRIBUTES);
  const rampsList = getResourceUrl(EResource.RAMPS_LIST);

  useEffect(() => {
    fetchAttributes();
  }, []);

  const fetchAttributes = async () => {
    setIsLoading(true);
    try {
      const { data } =
        await rampAttributesService.getRampsAttributes<
          IBasicResponse<ITicketAttribute[]>
        >(rampsAttributeUrl);

      data.sort(
        (a: ITicketAttribute, b: ITicketAttribute) => a.position - b.position,
      );

      setAttributes(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const save = async (data: any) => {
    const rampData = {
      attributes: data.attributes,
    };

    try {
      await rampService.createRamp(rampData);
      redirect("list", rampsList);
    } catch (e) {
      notify(
        e?.translatedMessage || translate("app.notifications.error.undefined"),
        { type: "error" },
      );
    }
  };

  return (
    <Create>
      <SimpleForm
        onSubmit={save}
        toolbar={<RampsCreateFormToolbar />}
        style={{ display: "flex", alignItems: "center" }}
      >
        <FormTitle>{translate("app.delivery_zone.ramps.form.title")}</FormTitle>
        <FormInnerContainer>
          {isLoading ? (
            <LoaderContainer>
              <CircularProgress size={100} />
            </LoaderContainer>
          ) : (
            <AttributesInputArray
              inputsData={attributes}
              inputComponentsMapKey="DeliveryZone_Ramp"
            />
          )}
        </FormInnerContainer>
      </SimpleForm>
    </Create>
  );
};

export default RampsCreate;
