import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslate } from "ra-core";
import { ConfigManager } from "../../../../constants/ConfigManager";
import { httpClient } from "../../../../providers/dataProvider";
import { IHandler } from "../../../../types/interfaces/schedule.interfaces";

export interface IUseHandlersProps {
  readonly module?: string;
}

export interface IUseHandlersResource {
  readonly fetchHandlers: () => Promise<void>;
  readonly handlers: { id: string; name: string }[];
}

const useHandlers = ({ module }: IUseHandlersProps): IUseHandlersResource => {
  const translate = useTranslate();

  const [loadedHandlers, setLoadedHandlers] = useState<IHandler[]>([]);
  const handlers = useMemo(() => {
    return loadedHandlers.map((handler) => ({
      id: handler.name,
      name: translate(`app.schedule.handlers.${handler.name}`) || handler.name,
    }));
  }, [loadedHandlers, translate]);

  const fetchHandlers = useCallback(async () => {
    const { json } = await httpClient(
      `${ConfigManager.getInstance().getApiAdminUrl()}/${module}/admin/group/schedule/handlers`,
      {
        method: "GET",
      },
    );

    return json.data;
  }, [module]);

  useEffect(() => {
    if (!module) return;
    fetchHandlers().then((data) => {
      setLoadedHandlers(data);
    });
  }, [fetchHandlers, module]);

  return { fetchHandlers, handlers };
};

export default useHandlers;
