import { ReactElement } from "react";
import { Resource } from "react-admin";
import TenantsIcon from "../../components/icons/TenantsIcon";
import { Roles } from "../../types/enums/roles";
import { IOrganization } from "../../types/interfaces/organization.interface";
import { EOrganizationType } from "../../types/enums/organization.enums";
import tenantsResources from "./tenants";

const tenants = (
  permissions: string[] | undefined,
  organization: IOrganization | null,
): ReactElement[] => {
  const resources = [];
  const tenantsUrl = "organization/admin/group/tenants";

  if (
    permissions &&
    permissions.some((role) => role.startsWith(Roles.ROLE_GROUP_ADMIN)) &&
    organization?.type === EOrganizationType.MULTI_TENANT
  ) {
    resources.push(
      <Resource
        key="tenants-parent"
        name="tenants-parent"
        icon={TenantsIcon}
        options={{
          label: "app.administration.administration_parent",
          isMenuParent: true,
          sectionKey: "administration-parent",
          sectionName: "app.system.system_application",
        }}
      />,
    );

    resources.push(
      <Resource
        {...tenantsResources}
        name={tenantsUrl}
        key={tenantsUrl}
        options={{
          label: "app.administration.tenants.tenants",
          menuParent: "tenants-parent",
          sectionKey: "tenants-section",
          sectionName: "app.system.system_application",
        }}
      />,
    );
  }

  return resources;
};

export default tenants;
