import React from "react";
import {
  Datagrid,
  Filter,
  FilterProps,
  TextField,
  useAuthenticated,
  BooleanInput,
  ListToolbarProps,
  TopToolbar,
  CreateButton,
  FunctionField,
  useTranslate,
} from "react-admin";
import { sanitizeListRestProps } from "ra-core";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { listTopFilter, StyledList } from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { ITenant } from "../../../types/interfaces/administration/tenant.interface";
import EmptyList from "../../../components/EmptyList";

const TenantsListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const TenantsListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <BooleanInput
      name="visible"
      source="visible"
      label="app.administration.tenants.visible"
      alwaysOn
    />
    <BooleanInput
      name="enabled"
      source="enabled"
      label="app.administration.tenants.enabled"
      alwaysOn
    />
  </Filter>
);

const TenantsListView = () => {
  const translate = useTranslate();
  return (
    <SkeletonLoaderList>
      <Datagrid
        bulkActionButtons={false}
        rowClick="show"
        empty={
          <EmptyList
            title={translate("app.administration.tenants.empty_list")}
          />
        }
      >
        <TextField
          source="name"
          label="app.administration.tenants.tenant_name"
          sortable={false}
        />
        <TextField
          source="displayName"
          label="app.administration.tenants.display_name"
          sortable={false}
        />
        <FunctionField
          label="app.administration.tenants.visible"
          sortable={false}
          render={(record: ITenant) => {
            return record.visible ? (
              <CheckIcon htmlColor="#00A044" />
            ) : (
              <CloseIcon htmlColor="#EC5B56" />
            );
          }}
        />
        <FunctionField
          label="app.administration.tenants.enabled"
          sortable={false}
          render={(record: ITenant) => {
            return record.enabled ? (
              <CheckIcon htmlColor="#00A044" />
            ) : (
              <CloseIcon htmlColor="#EC5B56" />
            );
          }}
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const TenantsListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const TenantsList = () => {
  useAuthenticated();
  return (
    <StyledList
      className="styled-list"
      filters={<TenantsListFilter />}
      pagination={<TenantsListPagination />}
      actions={<TenantsListTopToolbar />}
      exporter={false}
      disableSyncWithLocation={true}
      filterDefaultValues={{ visible: true, enabled: true }}
    >
      <TenantsListView />
    </StyledList>
  );
};

export default TenantsList;
