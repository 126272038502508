import React from "react";
import {
  BooleanInput,
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  useAuthenticated,
  useRecordContext,
  useTranslate,
  useNotify,
  useRedirect,
} from "react-admin";
import { Toolbar } from "@mui/material";
import { buttons, StyledTextInput } from "../../../themes/styles";
import { ITenant } from "../../../types/interfaces/administration/tenant.interface";
import { tenantsService } from "../../../api/administration/TenantsService";

const TenantEditFormToolbar = () => {
  const record = useRecordContext<ITenant>();
  const translate = useTranslate();
  const notify = useNotify();

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton type="submit" />
      <DeleteButton
        confirmContent={
          <div>
            {translate("app.administration.tenants.delete_confirm", {
              name: record?.displayName,
            })}
            ?
          </div>
        }
        confirmTitle={<div>{translate("app.auth.confirmation")}</div>}
      />
    </Toolbar>
  );
};

const TenantsEdit = () => {
  useAuthenticated();

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (values: any) => {
    try {
      const data = {
        ...values,
        name: values.displayName
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^a-z0-9_]/g, ""),
      };
      delete data.id;
      await tenantsService.updateTenant(values.id, data);
      notify(translate("app.administration.tenants.successfully_updated"), {
        type: "success",
      });
      redirect("list", "organization/admin/group/tenants");
    } catch (error) {
      if (error.subErrors.length > 0) {
        const errorMessages = error.subErrors
          .map((subError: any) => subError.message)
          .join("\n");
        notify(errorMessages, { type: "error", multiLine: true });
      } else if (error.translatedMessage) {
        notify(error.translatedMessage, { type: "error" });
      } else {
        notify("ra.notification.http_error", { type: "error" });
      }
    }
  };

  return (
    <Edit
      redirect="show"
      mutationMode="pessimistic"
      sx={{
        "& .MuiButton-text, .MuiButton-root": buttons.whiteButton,
        "& .RaEdit-main": {
          paddingTop: "40px",
        },
      }}
    >
      <SimpleForm toolbar={<TenantEditFormToolbar />} onSubmit={handleSubmit}>
        <StyledTextInput
          source="displayName"
          variant="outlined"
          label="app.administration.tenants.display_name"
          fullWidth
          validate={(value) => {
            if (!value) return "app.validation.required";
            if (value.length > 50) return "app.validation.max_length_50";
            return undefined;
          }}
        />
        <BooleanInput
          source="enabled"
          label="app.administration.tenants.enabled"
        />
        <BooleanInput
          source="visible"
          label="app.administration.tenants.visible"
        />
      </SimpleForm>
    </Edit>
  );
};

export default TenantsEdit;
