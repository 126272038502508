import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import polishMessages from "ra-language-polish";
import { resolveBrowserLocale } from "react-admin";
import appEnglishMessages from "./app-english";
import appPolishMessages from "./app-polish";

polishMessages.ra.action.unselect = "Odznacz";
polishMessages.ra.action.create = "Dodaj";
polishMessages.ra.navigation.skip_nav = "Pomiń";
polishMessages.ra.action.clear_array_input = "Usuń wartości";
polishMessages.ra.action.move_down = "Niżej";
polishMessages.ra.action.move_up = "Wyżej";
englishMessages.ra.action.unselect = "Unselect";
englishMessages.ra.navigation.skip_nav = "Skip";
englishMessages.ra.action.clear_array_input = "Clear";
englishMessages.ra.action.move_up = "Move up";
englishMessages.ra.action.move_down = "Move down";

export const messages = {
  en: { ...appEnglishMessages, ...englishMessages },
  pl: { ...appPolishMessages, ...polishMessages },
};

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale as keyof typeof messages],
  resolveBrowserLocale(),
  {
    allowMissing: true,
  },
);

export default i18nProvider;
