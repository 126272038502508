import React from "react";
import { Box } from "@mui/material";

type TProps = {
  title: string;
};

const EmptyList: React.FC<TProps> = ({ title }) => {
  return (
    <Box
      sx={{
        p: 3,
        textAlign: "center",
        my: 2,
        color: "text.secondary",
      }}
    >
      {title}
    </Box>
  );
};

export default EmptyList;
