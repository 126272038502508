import { AxiosRequestConfig } from "axios";
import { apiRequest } from "../client";
import { ConfigManager } from "../../constants/ConfigManager";

const getRampsAttributes = async <T>(apiUrl: string) => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${apiUrl}`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const rampAttributesService = {
  getRampsAttributes,
};
