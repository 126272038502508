import { useAuthenticated, useTranslate } from "ra-core";
import {
  Datagrid,
  Filter,
  FilterProps,
  FunctionField,
  TextField,
} from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import cronParser from "cron-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomPagination from "../../../components/common/CustomPagination";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import {
  listTopFilter,
  StyledList,
  StyledSelect,
} from "../../../themes/styles";
import { IOrganization } from "../../../types/interfaces/organization.interface";
import { OrganizationService } from "../../../api/OrganizationService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import CustomInputWrapper from "../../../components/common/CustomInputWrapper";
import EmptyList from "../../../components/EmptyList";

const TasksPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const TasksListFilter = (props: Partial<FilterProps>) => {
  const [organizationList, setOrganizationList] = useState<IOrganization[]>([]);

  const fetchOrgList = async () => {
    try {
      const { data } =
        await OrganizationService.getOrganizationsList<
          IBasicResponse<IOrganization[]>
        >();
      setOrganizationList(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchOrgList();
  }, []);

  return (
    <Filter {...props}>
      <CustomInputWrapper
        source="search"
        alwaysOn
        resettable
        startIcon={<SearchIcon color="disabled" fontSize="large" />}
        placeholder="app.schedule.list.searchPlaceholder"
        title="app.schedule.list.search"
      />
      <StyledSelect
        alwaysOn
        source="active"
        className="list-select-input"
        label="app.auth.active"
        emptyValue="all"
        emptyText={"app.helpdesk.all"}
        choices={[
          { id: "active", name: "app.helpdesk.active" },
          { id: "inactive", name: "app.helpdesk.inactive" },
        ]}
        variant="outlined"
      />
      <StyledSelect
        source="type"
        className="list-select-input"
        label="app.schedule.list.type"
        choices={[
          { id: "one-time", name: "app.schedule.taskTypes.one-time" },
          { id: "recurring", name: "app.schedule.taskTypes.recurring" },
        ]}
        variant="outlined"
      />
      <StyledSelect
        source="groupId"
        className="list-select-input"
        label="app.schedule.list.organization"
        choices={[
          { id: "null", name: "app.schedule.organization.system" },
          ...organizationList.map((org: IOrganization) => ({
            id: org.id,
            name: org.displayName,
          })),
        ]}
        variant="outlined"
      />
    </Filter>
  );
};

const TasksList = () => {
  useAuthenticated();
  const translate = useTranslate();

  return (
    <StyledList
      className="styled-list"
      pagination={<TasksPagination />}
      filters={<TasksListFilter />}
      empty={false}
      exporter={false}
      queryOptions={{ meta: { active: "all" } }}
      disableSyncWithLocation={true}
    >
      <SkeletonLoaderList>
        <Datagrid
          bulkActionButtons={false}
          rowClick="show"
          empty={
            <EmptyList title={translate("app.schedule.form.emptyTasks")} />
          }
        >
          <TextField
            source="name"
            label={translate("app.schedule.list.name")}
          />
          <FunctionField
            source="type"
            label={translate("app.schedule.list.type")}
            render={(record: any) => (
              <span>{translate(`app.schedule.taskTypes.${record.type}`)}</span>
            )}
          />
          <TextField
            source="module"
            label={translate("app.schedule.list.module")}
          />
          <FunctionField
            source="handler"
            label={translate("app.schedule.list.handler")}
            render={(record: any) => (
              <span>
                {translate(`app.schedule.handlers.${record.handler}`)}
              </span>
            )}
          />
          <FunctionField
            label={translate("app.schedule.list.upcoming_run")}
            render={(record: any) => {
              const type = record.type;

              if (type === "one-time" && record?.oneTime?.date) {
                return <span>{moment(record.oneTime.date).fromNow()}</span>;
              }

              if (type === "recurring" && record?.recurring?.cron) {
                const cron = record.recurring.cron;
                // const timeZone = record.recurring.timeZone;
                const nextDate = cronParser
                  .parseExpression(cron)
                  .next()
                  .toDate();

                return <span>{moment(nextDate).fromNow()}</span>;
              }

              return <span>{translate("app.schedule.list.unknown")}</span>;
            }}
          />
          <TextField source="groupId" label="app.schedule.list.organization" />
          <FunctionField
            label="Status"
            render={(record: any) => (
              <span>{translate(`app.helpdesk.status.${record.active}`)}</span>
            )}
          />
        </Datagrid>
      </SkeletonLoaderList>
    </StyledList>
  );
};

export default TasksList;
