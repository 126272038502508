import { AppBarProps, Logout, useGetIdentity, useRefresh } from "react-admin";
import { MenuItem, Select, Theme, useMediaQuery } from "@mui/material";
import { useCallback, memo, useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useStyles, standardAppBarStyle } from "../themes/styles";
import { useOrganizationLogo } from "../hooks/useOrganizationLogo";
import { OrganizationService } from "../api/OrganizationService";
import {
  IOrganization,
  IOrganizationAdmin,
} from "../types/interfaces/organization.interface";
import { EOrganizationType } from "../types/enums/organization.enums";
import { OrganizationManager } from "../constants/OrganizationManager";
import { IIdentity } from "../types/interfaces/identity.interface";
import StandardUserMenu from "./StandardUserMenu";
import LogoutIcon from "./icons/LogoutIcon";
import LogoIcon from "./icons/LogoIcon";
import { BasicAppBar } from "./BasicAppBar";

const LogoPlaceholder = memo(() => <div style={{ width: 60, height: 40 }} />);

const AppBarMenu = memo(() => (
  <div className="appBarMenu">
    <div className="appBarMenuItem">{/*<LocaleSwitcher />*/}</div>
    <div className="appBarMenuItem">
      <Logout icon={<LogoutIcon />} sx={standardAppBarStyle.logout} />
    </div>
  </div>
));

const LogoImage = memo(({ url }: { url: string }) => (
  <img
    src={url}
    alt="Organization Logo"
    style={{
      height: "40px",
      maxWidth: "200px",
      objectFit: "contain",
    }}
  />
));

const StyledSelect = styled(Select)({
  backgroundColor: "#fff",
  borderRadius: "8px",
  minWidth: "200px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    padding: "8px 14px",
    fontWeight: 400,
    color: "#333",
    fontSize: "14px",
  },
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.08)",
  "& .MuiSvgIcon-root": {
    color: "#757575",
  },
});

const TenantSwitcher = memo(() => {
  const refresh = useRefresh();
  const { isLoading: userLoading, data: userData } =
    useGetIdentity() as unknown as {
      isLoading: boolean;
      data: IIdentity;
    };
  const rootOrg = useMemo(() => {
    if (userLoading) {
      return null;
    }
    if (!userData) {
      return null;
    }
    const org = userData.groups.find(
      (g) => g.type === EOrganizationType.MULTI_TENANT,
    );
    if (!org) {
      return null;
    }

    return org;
  }, [userLoading, userData]);
  const [currentOrg, setCurrentOrg] = useState<string | null>(
    OrganizationManager.getInstance().getOrgId() || null,
  );
  const [orgInfo, setOrgInfo] = useState<IOrganizationAdmin | null>(null);

  const fetchOrgInfo = useCallback(async (orgId: string) => {
    if (!orgId) {
      return null;
    }

    try {
      const { data } = await OrganizationService.getOrganizationAdminInfo(
        orgId as string,
      );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, []);

  useEffect(() => {
    if (!rootOrg) {
      return;
    }
    fetchOrgInfo(rootOrg.id).then((data) => {
      setOrgInfo(data);
      setCurrentOrg(currentOrg);
    });
  }, [currentOrg, fetchOrgInfo, rootOrg]);

  const onOrgChange = (orgId: string): void => {
    setCurrentOrg(orgId);
    const tenant = orgInfo?.tenants?.find((t) => t.id === orgId);

    if (orgId === orgInfo?.id) {
      OrganizationManager.getInstance().setOrganization(
        orgInfo as IOrganization,
      );
    } else if (tenant) {
      OrganizationManager.getInstance().setOrganization(
        tenant as IOrganization,
      );
    }

    refresh();
  };

  if (!rootOrg) {
    return null;
  }

  if (!orgInfo || orgInfo.type === EOrganizationType.SINGLE_TENANT) {
    return null;
  }

  return (
    <StyledSelect
      size="small"
      variant="outlined"
      value={currentOrg}
      onChange={(e) => onOrgChange(e.target.value as string)}
      label="app.auth.organization"
    >
      <MenuItem value={orgInfo.id}>{orgInfo.displayName}</MenuItem>
      {orgInfo.tenants
        ?.filter((tenant) => tenant.enabled)
        .map((tenant) => (
          <MenuItem key={tenant.id} value={tenant.id}>
            {tenant.displayName}
          </MenuItem>
        ))}
    </StyledSelect>
  );
});

const StandardAppBar = (props: AppBarProps) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { logoUrl, isLoading, hasError } = useOrganizationLogo();

  const renderLogo = useCallback(() => {
    if (isLoading) {
      return <LogoPlaceholder />;
    }

    if (hasError) {
      return <LogoIcon width={60} height={60} />;
    }

    if (logoUrl) {
      return <LogoImage url={logoUrl} />;
    }

    return null;
  }, [isLoading, hasError, logoUrl]);

  return (
    <BasicAppBar
      userMenu={<StandardUserMenu />}
      position="fixed"
      {...props}
      sx={standardAppBarStyle.appBar}
    >
      {isSmall && <span className={classes.spacer} />}
      {renderLogo()}
      <div style={{ marginLeft: "2rem" }}>
        <TenantSwitcher />
      </div>
      <span className={classes.spacer} />
      {!isSmall && <AppBarMenu />}
    </BasicAppBar>
  );
};

export default memo(StandardAppBar);
