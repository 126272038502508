import {
  Create,
  FormDataConsumer,
  required,
  SimpleForm,
  Toolbar,
  TranslatableInputs,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileRejection } from "react-dropzone";
import CustomAttachmentField from "../../../components/forms/CustomAttachmentField";
import {
  buttons,
  StyledSaveButton,
  StyledSelect,
  StyledTextInput,
  StyledNumberInput,
  StyledImageInput,
} from "../../../themes/styles";
import { EModuleType } from "../../../types/enums/mobile/mobile-module-type.enum";
import { mobileModulesService } from "../../../api/MobileModulesService";
import useUploadModuleIcon from "../../../hooks/useUploadModuleIcon";
import moduleTypesChoices from "./module-types";

const ModuleCreateCreate = () => {
  return (
    <Toolbar>
      <StyledSaveButton sx={buttons.whiteButton} />
    </Toolbar>
  );
};

const ModulesCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const translate = useTranslate();

  const {
    uploadedAttachmentId,
    deleteUploadedFile,
    uploadFiles,
    handleFileRejections,
  } = useUploadModuleIcon();

  const onSubmit = async (data: any) => {
    try {
      const newModule = {
        ...data,
        iconId: uploadedAttachmentId,
      };
      await mobileModulesService.createMobileModule(newModule);
      refresh();
      notify(translate("app.mobile.module_was_created"), { type: "success" });
      redirect("list", "mobile/admin/group/modules");
    } catch (e) {
      notify(
        e?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  return (
    <Create>
      <SimpleForm toolbar={<ModuleCreateCreate />} onSubmit={onSubmit}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.mobile.name"
            validate={required()}
          />
        </TranslatableInputs>
        <StyledSelect
          variant="outlined"
          source="type"
          label="app.mobile.type"
          choices={moduleTypesChoices}
          sx={{ mt: 3 }}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            switch (formData.type) {
              case EModuleType.Parking:
                return <></>;
              case EModuleType.Helpdesk:
                return <></>;
              case EModuleType.WebFrame:
                return (
                  <StyledTextInput
                    variant="outlined"
                    source="ref"
                    label="app.mobile.url"
                    type="url"
                    style={{ marginTop: 20 }}
                    validate={required()}
                    {...rest}
                  />
                );
            }
          }}
        </FormDataConsumer>
        <StyledNumberInput
          variant="outlined"
          source="position"
          label="app.mobile.position"
          min={0}
          sx={{ marginTop: "15px" }}
          validate={required()}
        />
        <StyledImageInput
          defaultValue={[]}
          source="iconId"
          label="app.mobile.icon"
          accept={["image/jpeg", "image/png", "image/gif", "image/bmp"]}
          removeIcon={DeleteIcon}
          validate={required()}
          onRemove={() => deleteUploadedFile()}
          options={{
            onDrop: (files: File[]) => uploadFiles(files),
            onDropRejected: (fileRejections: FileRejection[]) =>
              handleFileRejections(fileRejections),
          }}
        >
          <CustomAttachmentField />
        </StyledImageInput>
      </SimpleForm>
    </Create>
  );
};

export default ModulesCreate;
