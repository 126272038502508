import { ComponentType } from "react";
import { EAllowedAttributeType } from "../../types/enums/ticket-attribute-type.enums";
import {
  StyledDateInput,
  StyledNumberInput,
  StyledRichTextInput,
  StyledTextInput,
} from "../../themes/styles";
import TicketAttributeSelectInput from "../../components/resources/helpdesk/tickets/TicketAttributeSelectInput";

export interface IInputTypeComponentMap {
  [key: string]: ComponentType<any>;
}

export const baseInputTypeComponentMap: IInputTypeComponentMap = {
  [EAllowedAttributeType.SingleLineText]: StyledTextInput,
  [EAllowedAttributeType.MultiLineText]: StyledRichTextInput,
  [EAllowedAttributeType.Integer]: StyledNumberInput,
  [EAllowedAttributeType.Decimal]: StyledNumberInput,
  [EAllowedAttributeType.DateTime]: StyledDateInput,
  [EAllowedAttributeType.SingleSelect]: TicketAttributeSelectInput,
};
