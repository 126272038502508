import { AxiosRequestConfig } from "axios";
import { apiRequest } from "../client";

const getSettings = async <T>(apiUrl: string) => {
  const orgId = localStorage.getItem("orgId");

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${apiUrl}/api/v1/delivery/public/${orgId}/settings`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const deliveryService = {
  getSettings,
};
