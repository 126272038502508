export enum Roles {
  ROLE_APP_ADMIN = "ROLE_APP_ADMIN",
  ROLE_APP_MANAGEMENT_ACCESS = "ROLE_APP_MANAGEMENT_ACCESS",
  ROLE_GROUP_ADMIN = "ROLE_GROUP_ADMIN",
  ROLE_MODULE_HELPDESK_ADMIN = "ROLE_MODULE_HELPDESK_ADMIN",
  ROLE_MODULE_HELPDESK_ASSIGNEE = "ROLE_MODULE_HELPDESK_ASSIGNEE",
  ROLE_MODULE_PARKING_ADMIN = "ROLE_MODULE_PARKING_ADMIN",
  ROLE_MODULE_PARKING_MANAGER = "ROLE_MODULE_PARKING_MANAGER",
  ROLE_MODULE_DELIVERY_ADMIN = "ROLE_MODULE_DELIVERY_ADMIN",
}
