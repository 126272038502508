import { BooleanInput } from "react-admin";
import {
  baseInputTypeComponentMap,
  IInputTypeComponentMap,
} from "../baseInputTypeComponentMap";
import { EAllowedAttributeType } from "../../../types/enums/ticket-attribute-type.enums";
import { StyledNumberInput, StyledTextInput } from "../../../themes/styles";
import EmailInput from "../../../components/forms/EmailInput";
import PhoneInput from "../../../components/forms/PhoneInput";
import PlateNumberInput from "../../../components/forms/PlateNumberInput";

export const rampInputTypeComponentMap: IInputTypeComponentMap = {
  ...baseInputTypeComponentMap,
  [EAllowedAttributeType.Email]: EmailInput,
  [EAllowedAttributeType.Phone]: PhoneInput,
  [EAllowedAttributeType.PlateNumber]: PlateNumberInput,
  [EAllowedAttributeType.Length]: StyledTextInput,
  [EAllowedAttributeType.Mass]: StyledTextInput,
  // not supported by API for now
  // [EAllowedAttributeType.Duration]: StyledNumberInput,
  [EAllowedAttributeType.Boolean]: BooleanInput,
};
