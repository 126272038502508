import React from "react";
import { Resource } from "react-admin";
import mobileAppIcon from "../../components/icons/MobileAppIcon";
import modulesResource from "./mobile-modules";
import moduleOnboardingPagesResource, {
  moduleOnboardingPagesResource1,
} from "./module-onboarding-pages";

const mobile = () => {
  const modulesUrl = "mobile/admin/group/modules";
  const modulePageCreateUrl = "mobile/admin/group/modules/:moduleId/pages";

  const modulesPageEdit = "mobile/admin/group/modules-pages";

  return [
    <Resource
      key="mobile-parent"
      name="mobile-parent"
      icon={mobileAppIcon}
      options={{
        label: "app.mobile.mobile_parent",
        isMenuParent: true,
        sectionName: "app.system.system_application",
      }}
    />,

    <Resource
      key={modulePageCreateUrl}
      name={modulePageCreateUrl}
      {...moduleOnboardingPagesResource}
      options={{
        label: "app.mobile.pages",
        menuParent: "mobile-parent",
      }}
    />,

    <Resource
      key={modulesPageEdit}
      name={modulesPageEdit}
      {...moduleOnboardingPagesResource1}
      options={{
        label: "app.mobile.pages",
        menuParent: "mobile-parent",
      }}
    />,

    <Resource
      key={modulesUrl}
      name={modulesUrl}
      {...modulesResource}
      options={{
        label: "app.mobile.mobile_modules",
        menuParent: "mobile-parent",
      }}
    />,
  ];
};

export default mobile;
