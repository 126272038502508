import * as Yup from "yup";
import isPasswordStrong from "validator/es/lib/isStrongPassword";
import i18nProvider from "../translations/i18n-provider";

const { translate } = i18nProvider;

export const loginScheme = Yup.object().shape({
  email: Yup.string()
    .email(translate("app.validation.email"))
    .required(translate("app.validation.required_field")),

  password: Yup.string()
    .min(8, translate("app.validation.minimum_characters", { number: 8 }))
    .max(40, translate("app.validation.maximum_characters", { number: 40 }))
    .required(translate("app.validation.required_field"))
    .test(
      "password-is-strong",
      translate("app.validation.password_requirements"),
      (value) =>
        isPasswordStrong(value, {
          minLength: 8,
          minLowercase: 1,
          minNumbers: 1,
          minSymbols: 1,
          minUppercase: 1,
        }),
    ),
});

export const codeScheme = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]+$/, {
      message: translate("app.validation.only_numbers_allowed"),
    })
    .length(
      8,
      translate("app.validation.minimum_characters", {
        number: 8,
      }),
    )
    .required(translate("app.validation.required_field")),
});

export const resetScheme = Yup.object().shape({
  password: Yup.string()
    .min(8, translate("app.validation.minimum_characters", { number: 8 }))
    .max(40, translate("app.validation.maximum_characters", { number: 40 }))
    .required(translate("app.validation.required_field"))
    .test(
      "password-is-strong",
      translate("app.validation.password_requirements"),
      (value) =>
        isPasswordStrong(value, {
          minLength: 8,
          minLowercase: 1,
          minNumbers: 1,
          minSymbols: 1,
          minUppercase: 1,
        }),
    ),
});

export const plateNumberScheme = Yup.object().shape({
  plateNumber: Yup.string()
    .min(1, translate("app.validation.minimum_characters", { number: 1 }))
    .max(50, translate("app.validation.maximum_characters", { number: 50 }))
    .matches(
      /^[A-Z0-9]*$/,
      translate(
        "app.validation.only_numbers_and_uppercase_letters_are_allowed",
      ),
    )
    .required(translate("app.validation.required_field")),
});

export const freeParkingRegistration = Yup.object().shape({
  firstName: Yup.string()
    .min(2, translate("app.validation.minimum_characters", { number: 2 }))
    .max(50, translate("app.validation.maximum_characters", { number: 50 }))
    .matches(
      /^[-.,'()/\s\p{L}]+$/mu,
      translate("app.validation.incorrect_format"),
    ),
  lastName: Yup.string()
    .min(2, translate("app.validation.minimum_characters", { number: 2 }))
    .max(50, translate("app.validation.maximum_characters", { number: 50 }))
    .matches(
      /^[-.,'()/\s\p{L}]+$/mu,
      translate("app.validation.incorrect_format"),
    ),
  email: Yup.string().email(translate("app.validation.email")),
  phoneNumber: Yup.string()
    .min(8, translate("app.validation.minimum_characters", { number: 8 }))
    .required(translate("app.validation.required_field")),
});

export const parkingRegistration = Yup.object().shape({
  firstName: Yup.string()
    .min(2, translate("app.validation.minimum_characters", { number: 2 }))
    .max(50, translate("app.validation.maximum_characters", { number: 50 }))
    .matches(
      /^[-.,'()/\s\p{L}]+$/mu,
      translate("app.validation.incorrect_format"),
    ),
  lastName: Yup.string()
    .min(2, translate("app.validation.minimum_characters", { number: 2 }))
    .max(50, translate("app.validation.maximum_characters", { number: 50 }))
    .matches(
      /^[-.,'()/\s\p{L}]+$/mu,
      translate("app.validation.incorrect_format"),
    ),
  email: Yup.string()
    .email(translate("app.validation.email"))
    .required(translate("app.validation.required_field")),
  phoneNumber: Yup.string()
    .min(8, translate("app.validation.minimum_characters", { number: 8 }))
    .required(translate("app.validation.required_field")),
});

export const resetPasswordScheme = Yup.object().shape({
  email: Yup.string()
    .email(translate("app.auth.login.email_invalid"))
    .required(translate("app.auth.login.email_required")),
  password: Yup.string()
    .required(translate("app.auth.login.password_required"))
    .min(8, translate("app.auth.login.password_min_length")),
  confirmPassword: Yup.string()
    .required(translate("app.auth.login.confirm_password_required"))
    .oneOf(
      [Yup.ref("password")],
      translate("app.auth.login.passwords_must_match"),
    ),
});
