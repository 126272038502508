import { useState, useEffect, useCallback } from "react";
import { OrganizationService } from "../api/OrganizationService";
import { OrganizationManager } from "../constants/OrganizationManager";

interface IUseOrganizationLogoResult {
  logoUrl: string | null;
  isLoading: boolean;
  hasError: boolean;
  fetchLogo: (orgId?: string) => Promise<void>;
}

export const useOrganizationLogo = (
  initialOrgId?: string,
): IUseOrganizationLogoResult => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const fetchLogo = useCallback(
    async (orgId?: string) => {
      const targetOrgId = orgId || OrganizationManager.getInstance().getOrgId();

      if (!targetOrgId) {
        setHasError(true);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setHasError(false);

      try {
        if (logoUrl) {
          URL.revokeObjectURL(logoUrl);
        }

        const customerLogo =
          await OrganizationService.getOrganizationLogo<Blob>(targetOrgId);
        const newLogoUrl = URL.createObjectURL(customerLogo);
        setLogoUrl(newLogoUrl);
        setHasError(false);
      } catch (error) {
        setLogoUrl(null);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [logoUrl],
  );

  useEffect(() => {
    const orgId = initialOrgId || OrganizationManager.getInstance().getOrgId();
    if (orgId) {
      fetchLogo(orgId);
    }
  }, [initialOrgId]);

  return { logoUrl, isLoading, hasError, fetchLogo };
};
