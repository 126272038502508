import React from "react";
import { CircularProgress, FormControl, Box } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import { useTranslate } from "react-admin";
import {
  loginScheme,
  resetPasswordScheme,
} from "../../utils/validationSchemes";

import {
  CustomButton,
  CustomFormLabel,
  FormTextInput,
} from "../../themes/styles";
import ErrorMessageContainer from "../errors/ErrorContainer";
import PasswordInput from "../forms/PasswordInput";
import { getPasswordBackground } from "../../utils/passwordService";
import useParkingSettings from "../../hooks/useParkingSettings";
import useHelpdeskSettings from "../../hooks/useHelpdeskSettings";
import useDeliverySettings from "../../hooks/useDeliverySettings";
import { OrganizationManager } from "../../constants/OrganizationManager";

type TProps = {
  isLoading: boolean;
  resetPasswordProcess: boolean;
  handleResetPassword: (data: {
    email: string;
    password: string;
    orgId: string;
  }) => void;
  handleLogin: (data: {
    email: string;
    password: string;
    orgId: string;
  }) => void;
  formikRef: { current: FormikProps<IFormValues> | null };
};

interface IFormValues {
  email: string;
  password: string;
  confirmPassword?: string;
}

const LoginPageForm = (props: TProps) => {
  const {
    isLoading,
    formikRef,
    resetPasswordProcess,
    handleLogin,
    handleResetPassword,
  } = props;
  const translate = useTranslate();

  const orgId = OrganizationManager.getInstance().getOrgId();

  useHelpdeskSettings(orgId);
  useParkingSettings(orgId);
  useDeliverySettings(orgId);

  const handleSubmit = async (data: {
    email: string;
    password: string;
    confirmPassword?: string;
  }) => {
    const newData = {
      email: data.email,
      password: data.password,
      orgId: orgId,
    };

    if (resetPasswordProcess) {
      handleResetPassword(newData);
    } else {
      handleLogin(newData);
    }
  };

  const initialValues = resetPasswordProcess
    ? { email: "", password: "", confirmPassword: "" }
    : { email: "", password: "" };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        resetPasswordProcess ? resetPasswordScheme : loginScheme
      }
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={(f) => (formikRef.current = f)}
      key="AuthForm"
    >
      {({ touched, values, handleChange, errors, setFieldTouched }) => (
        <Form style={{ flexDirection: "column", display: "flex" }}>
          <FormControl>
            <CustomFormLabel>Email</CustomFormLabel>
            <Field>
              {() => (
                <FormTextInput
                  inputProps={{ "data-testid": "email" }}
                  name="email"
                  id="email"
                  type="email"
                  value={values.email}
                  onBlur={() => setFieldTouched("email")}
                  onChange={handleChange("email")}
                />
              )}
            </Field>
          </FormControl>

          {errors.email && touched.email && (
            <div data-testid="emailError">
              <ErrorMessageContainer>{errors.email}</ErrorMessageContainer>
            </div>
          )}

          <FormControl>
            <CustomFormLabel>
              {resetPasswordProcess
                ? translate("app.auth.login.new_password")
                : translate("app.auth.login.password")}
            </CustomFormLabel>
            <Field>
              {() => (
                <PasswordInput
                  name="password"
                  inputProps={{ "data-testid": "password" }}
                  value={values.password}
                  onChange={handleChange("password")}
                  onBlur={() => setFieldTouched("password")}
                  style={{
                    backgroundColor: getPasswordBackground(
                      !errors.password,
                      touched?.password,
                    ),
                  }}
                />
              )}
            </Field>
          </FormControl>

          {errors.password && touched.password && (
            <div data-testid="passwordError">
              <ErrorMessageContainer>{errors.password}</ErrorMessageContainer>
            </div>
          )}

          {resetPasswordProcess && (
            <>
              <FormControl>
                <CustomFormLabel>
                  {translate("app.auth.login.confirm_password")}
                </CustomFormLabel>
                <Field>
                  {() => (
                    <PasswordInput
                      name="confirmPassword"
                      inputProps={{ "data-testid": "confirmPassword" }}
                      value={values.confirmPassword || ""}
                      onChange={handleChange("confirmPassword")}
                      onBlur={() => setFieldTouched("confirmPassword")}
                      style={{
                        backgroundColor: getPasswordBackground(
                          !errors.confirmPassword,
                          touched?.confirmPassword,
                        ),
                      }}
                    />
                  )}
                </Field>
              </FormControl>

              {errors.confirmPassword && touched.confirmPassword && (
                <div data-testid="confirmPasswordError">
                  <ErrorMessageContainer>
                    {errors.confirmPassword}
                  </ErrorMessageContainer>
                </div>
              )}
            </>
          )}

          <Box mt={4}>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <CustomButton
                type="submit"
                aria-label="submitLogin"
                disabled={
                  resetPasswordProcess
                    ? Object.values(values).some((val) => !val) ||
                      values.password !== values.confirmPassword
                    : !values.email || !values.password
                }
                style={{
                  width: "100%",
                }}
              >
                {translate("app.auth.next")}
              </CustomButton>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default LoginPageForm;
