import React, { useEffect, useState } from "react";
import {
  Create,
  ListToolbarProps,
  required,
  SimpleForm,
  useTranslate,
  useNotify,
  useRedirect,
} from "react-admin";
import { CircularProgress, Toolbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete/Autocomplete";
import {
  StyledDateInput,
  StyledSaveButton,
  StyledMUIAutocompleteInput,
} from "../../../themes/styles";
import { IUser } from "../../../types/interfaces/user.interface";
import { userService } from "../../../api/UserService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { adminParkingService } from "../../../api/AdminParkingService";
import { IReservationVehicleItem } from "../../../types/interfaces/admin-parking/reservation-vehicle-item.interface";

type User = {
  id: string;
  name: string;
};

const CustomTextField = ({
  params,
  label,
  loading,
}: {
  params: AutocompleteRenderInputParams;
  label?: string;
  loading: boolean;
}) => {
  return (
    <TextField
      {...params}
      label={label}
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

const ReservationsCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const ReservationsCreate = () => {
  const [options, setOptions] = useState<User[]>([]);
  const [vehicleOptions, setVehicleOptions] = useState<User[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);

  const [plateNumber, setPlateNumber] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const notify = useNotify();
  const redirect = useRedirect();

  const translate = useTranslate();

  useEffect(() => {
    loadSearchResult();
  }, []);

  useEffect(() => {
    if (userId) {
      loadVehicles();
    }
  }, [userId]);

  const convertToAppropriateFormat = (data: IUser[]) => {
    return data.map((item: IUser) => ({
      id: item.id,
      name:
        item.firstName + " " + item.lastName[0] + "." + " (" + item.email + ")",
    }));
  };

  const convertVehiclesToAppropriateFormat = (
    data: IReservationVehicleItem[],
  ) => {
    return data.map((item: IReservationVehicleItem) => ({
      id: item.plateNumber,
      name: item.plateNumber,
    }));
  };

  const fetchUsers = async (params: { search?: string }) => {
    const requestParams: { search?: string; limit?: number } = {};
    if (params.search) {
      requestParams.search = params.search;
    }
    requestParams.limit = 100;
    const { data } =
      await userService.getUserList<IBasicResponse<IUser[]>>(requestParams);
    return data;
  };

  const fetchVehicles = async (params: { search?: string }) => {
    const requestParams: {
      search?: string;
      userId: string;
      limit?: number;
    } = {
      userId,
    };
    if (params.search) {
      requestParams["search"] = params.search;
    }

    const { data } =
      await adminParkingService.getRegisteredVehicles<
        IBasicResponse<IReservationVehicleItem[]>
      >(requestParams);
    return data;
  };

  const loadVehicles = async (searchValue?: string) => {
    try {
      setIsLoading(true);
      const vehicles = await fetchVehicles({ search: searchValue });
      setVehicleOptions(convertVehiclesToAppropriateFormat(vehicles));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadSearchResult = async (searchValue?: string) => {
    try {
      setIsLoading(true);
      const users = await fetchUsers({ search: searchValue });
      setOptions(convertToAppropriateFormat(users));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const createReservation = async (data: any) => {
    if (!userId || !plateNumber) {
      notify(translate("app.parking.validation.creating_form"), {
        type: "error",
      });
      return;
    }
    const reservationData = { ...data, userId, plateNumber };
    try {
      await adminParkingService.createReservation(reservationData);
      notify(translate("app.parking.reservation_was_created"), {
        type: "success",
      });
      redirect("list", "parking/admin/group/reservations");
    } catch (e) {
      notify(
        e?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  const autocompleteProps: AutocompleteProps<any, boolean, boolean, boolean> = {
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionLabel: (option) => option.name,
    loading,
    options: [],
    sx: { width: "550px" },
    renderInput: (params: AutocompleteRenderInputParams) => (
      <CustomTextField params={params} loading={loading} />
    ),
  };

  const usersAutocompleteProps: AutocompleteProps<
    any,
    boolean,
    boolean,
    boolean
  > = {
    ...autocompleteProps,
    fullWidth: true,
    onInputChange: (_, newInputValue) => {
      loadSearchResult(newInputValue);
    },
    onChange: (_, value) => setUserId(value?.id),
    options,
    renderInput: (params: AutocompleteRenderInputParams) => (
      <CustomTextField
        params={params}
        label={translate("app.helpdesk.choose_user")}
        loading={loading}
      />
    ),
  };

  const vehiclesAutocompleteInput: AutocompleteProps<
    any,
    boolean,
    boolean,
    boolean
  > = {
    ...autocompleteProps,
    onInputChange: (_, newInputValue) => {
      loadVehicles(newInputValue);
    },
    onChange: (_, value) => setPlateNumber(value.id),
    options: vehicleOptions,
    renderInput: (params: AutocompleteRenderInputParams) => (
      <CustomTextField
        params={params}
        label={translate("app.helpdesk.choose_vehicle")}
        loading={loading}
      />
    ),
  };

  return (
    <Create>
      <SimpleForm
        toolbar={<ReservationsCreateFormToolbar />}
        onSubmit={createReservation}
        sx={{ "& .MuiStack-root": { gap: "15px" } }}
      >
        <StyledMUIAutocompleteInput {...usersAutocompleteProps} />

        {userId && (
          <StyledMUIAutocompleteInput {...vehiclesAutocompleteInput} />
        )}
        <StyledDateInput
          label="app.parking.reservationFrom"
          source="from"
          variant="outlined"
          validate={required()}
          sx={{ width: "550px" }}
        />
        <StyledDateInput
          label="app.parking.reservationTo"
          source="to"
          variant="outlined"
          validate={required()}
          sx={{ width: "550px" }}
        />
      </SimpleForm>
    </Create>
  );
};

export default ReservationsCreate;
