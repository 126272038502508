import TenantsList from "./TenantsList";
import TenantsCreate from "./TenantsCreate";
import TenantsShow from "./TenantsShow";
import TenantsEdit from "./TenantsEdit";

const tenantsResources = {
  list: TenantsList,
  create: TenantsCreate,
  show: TenantsShow,
  edit: TenantsEdit,
};

export default tenantsResources;
