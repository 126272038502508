import React, { useCallback, useState } from "react";
import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  useNotify,
  useTranslate,
} from "react-admin";
import { ITransaction } from "../../../../types/interfaces/admin-parking/transaction.interface";
import { IRefundRequest } from "../../../../types/interfaces/admin-parking/refund-request.interface";
import { httpClient } from "../../../../providers/dataProvider";
import { ConfigManager } from "../../../../constants/ConfigManager";
import RefundDialog from "./RefundDialog";

const TransactionsTable = () => {
  const [activeTransaction, setActiveTransaction] = useState<ITransaction>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notify = useNotify();
  const translate = useTranslate();

  const createRefund = async (data: Partial<IRefundRequest>) => {
    const newData: Partial<IRefundRequest> = {
      paymentId: activeTransaction?.id,
      reason: data.reason,
      amount: data.amount,
    };

    if (data?.reasonDetails) {
      newData.reasonDetails = data.reasonDetails;
    }

    if (newData.amount) {
      newData.amount = Math.round(newData.amount * 100);
    }

    try {
      setIsLoading(true);
      await httpClient(
        `${ConfigManager.getInstance().getApiAdminUrl()}/parking/admin/group/refunds`,
        {
          method: "POST",
          body: JSON.stringify(newData),
        },
      );

      handleCloseDialog();
      notify(translate("app.parking.refund_was_created"), { type: "success" });
    } catch (e) {
      const message = e?.body?.error?.translatedMessage
        ? `app.parking.${e?.body?.error?.translatedMessage}`
        : "app.notifications.error.unexpected_error";
      notify(translate(message), { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = useCallback(() => {
    setIsOpen(false);
    setActiveTransaction(undefined);
  }, []);

  return (
    <>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <DateField source="createdAt" label="app.parking.created_at" />
        <FunctionField
          label="app.parking.amount"
          render={(record: ITransaction) =>
            `${(record.amount / 100).toFixed(2)}`
          }
        />
        <FunctionField
          label="app.parking.refunded_amount"
          render={(record: ITransaction) => {
            if (record.refundAmount) {
              return `${(record.refundAmount / 100).toFixed(2)}`;
            }
          }}
        />
        <TextField
          source="currency"
          label="app.parking.currency"
          sortable={false}
        />

        <FunctionField
          label="app.parking.transaction_type"
          render={(record: ITransaction) =>
            translate(`app.parking.${record.type}`)
          }
        />

        <FunctionField
          label="app.parking.transaction_status"
          render={(record: ITransaction) =>
            translate(`app.parking.transaction_statuses.${record.status}`)
          }
        />

        <FunctionField
          render={(record: ITransaction) => {
            if (!record.isRefundable) {
              return <></>;
            }

            return (
              <Button
                label="app.parking.refund"
                onClick={(event: any) => {
                  event.stopPropagation();
                  setIsOpen(true);
                  setActiveTransaction(record);
                }}
              />
            );
          }}
        />
      </Datagrid>
      <RefundDialog
        isOpen={isOpen}
        isLoading={isLoading}
        handleClose={handleCloseDialog}
        handleSubmit={createRefund}
        transaction={activeTransaction}
      />
    </>
  );
};

export default TransactionsTable;
