import React from "react";
import { Box, Grid } from "@mui/material";
import {
  useTranslate,
  useShowController,
  useAuthenticated,
  FunctionField,
  DateField,
  ReferenceOneField,
  RichTextField,
} from "react-admin";
import { itemInfoComponent, StyledShow } from "../../../themes/styles";
import { IRefundItem } from "../../../types/interfaces/admin-parking/refund-item.interface";
import { IUser } from "../../../types/interfaces/user.interface";
import { ITransaction } from "../../../types/interfaces/admin-parking/transaction.interface";

const RefundShow = () => {
  useAuthenticated();
  const translate = useTranslate();
  const { record } = useShowController<IRefundItem>();

  return (
    <StyledShow>
      <Box sx={itemInfoComponent}>
        <Grid container spacing={3} sx={{ ml: 0, mt: 0, mb: 2, p: 3 }}>
          <Grid className="item-info__container" item xs={5}>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.refund_status")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  label="app.parking.transaction_status"
                  render={(record: IRefundItem) => (
                    <span className="item-info__value">
                      {translate(
                        `app.parking.transaction_statuses.${record.status}`,
                      )}
                    </span>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.amount")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  render={(record: IRefundItem) => (
                    <span className="item-info__value">
                      {`${(record.amount / 100).toFixed(2)}`}
                    </span>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.created_at")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <DateField source="createdAt" className="item-info__value" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.reason")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  label="app.parking.reason"
                  render={(record: IRefundItem) => (
                    <b>{translate(`app.parking.${record.reason}`)}</b>
                  )}
                />
              </Grid>
            </Grid>
            {record?.reasonDetails && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.parking.description_of_reason")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <RichTextField
                    source="reasonDetails"
                    className="item-info__value"
                  />
                </Grid>
              </Grid>
            )}
            {record?.failureReason && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.parking.failure_reason")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <RichTextField
                    source="failureReason"
                    className="item-info__value"
                  />
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.issuer")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <ReferenceOneField
                  reference="admin/group/users"
                  target="paymentId"
                  link="show"
                >
                  <FunctionField
                    render={(record: IUser) => {
                      return (
                        <span className="item-info__value--link">{`${record.firstName} ${record.lastName[0]}.`}</span>
                      );
                    }}
                  />
                </ReferenceOneField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.parking.transaction")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <ReferenceOneField
                  reference="parking/admin/group/transactions"
                  target="issuerId"
                  link="show"
                >
                  <FunctionField
                    render={(record: ITransaction) => {
                      return (
                        <span className="item-info__value--link">
                          {record.id}
                        </span>
                      );
                    }}
                  />
                </ReferenceOneField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledShow>
  );
};

export default RefundShow;
