import React from "react";
import { InputProps, Validator, regex } from "react-admin";
import { StyledTextInput } from "../../themes/styles";

interface IPlateNumberInputProps extends InputProps {
  validate: Validator[];
}

const PlateNumberInput: React.FC<IPlateNumberInputProps> = (props) => {
  const { validate, ...rest } = props;
  const plateNumberRegex = /^[A-Z]{1,3}\s?[A-Z0-9]{4,5}$/i;
  const validators = [regex(plateNumberRegex), ...(validate || [])];

  return <StyledTextInput {...rest} validate={validators} />;
};

export default PlateNumberInput;
