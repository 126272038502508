import React from "react";
import {
  TextField,
  useAuthenticated,
  useTranslate,
  TopToolbar,
  EditButton,
  FunctionField,
} from "react-admin";
import { Box, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  itemInfoComponent,
  listTopFilter,
  StyledShow,
} from "../../../themes/styles";
import { ITenant } from "../../../types/interfaces/administration/tenant.interface";

const TenantsShowActions = () => (
  <TopToolbar sx={listTopFilter.topToolbar.rightFilters}>
    <EditButton />
  </TopToolbar>
);

const TenantsShow = () => {
  useAuthenticated();

  const translate = useTranslate();

  return (
    <StyledShow actions={<TenantsShowActions />}>
      <Box sx={itemInfoComponent}>
        <Grid container spacing={3} sx={{ ml: 0, mt: 0, mb: 2, p: 3 }}>
          <Grid className="item-info__container" item xs={5}>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.administration.tenants.tenant_name")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  source="name"
                  className="item-info__value"
                  sx={{ color: "black", fontWeight: "bold" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.administration.tenants.display_name")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  source="displayName"
                  className="item-info__value"
                  sx={{ color: "black", fontWeight: "bold" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.administration.tenants.enabled")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  label="app.administration.tenants.enabled"
                  sortable={false}
                  render={(record: ITenant) => {
                    return record.enabled ? (
                      <CheckIcon htmlColor="#00A044" />
                    ) : (
                      <CloseIcon htmlColor="#EC5B56" />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <span className="item-info__title">
                  {translate("app.administration.tenants.visible")}
                </span>
              </Grid>
              <Grid item xs={6}>
                <FunctionField
                  label="app.administration.tenants.enabled"
                  sortable={false}
                  render={(record: ITenant) => {
                    return record.visible ? (
                      <CheckIcon htmlColor="#00A044" />
                    ) : (
                      <CloseIcon htmlColor="#EC5B56" />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledShow>
  );
};

export default TenantsShow;
