import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../constants/ConfigManager";
import { IReservationCreate } from "../types/interfaces/admin-parking/reservation-create.interface";
import { OrganizationManager } from "../constants/OrganizationManager";
import { apiRequest } from "./client";

const getRegisteredVehicles = async <T>(params?: {
  search?: string;
  limit?: number;
}) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/parking/admin/${orgId}/vehicles-registered`,
      params,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getParkingSettings = async <T>(apiUrl: string) => {
  const orgId = localStorage.getItem("orgId");

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${apiUrl}/api/v1/parking/public/${orgId}/settings`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const createReservation = async <T>(data: IReservationCreate) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/parking/admin/${orgId}/reservations`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const modifyTicket = async <T>(
  plateNumber: string,
  data: { extend: number },
) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/parking/admin/${orgId}/tickets-management/${plateNumber}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const adminParkingService = {
  getRegisteredVehicles,
  createReservation,
  getParkingSettings,
  modifyTicket,
};
