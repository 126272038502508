import React, { useEffect, useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  ListToolbarProps,
  TextField,
  TopToolbar,
  useAuthenticated,
  useList,
  useListContext,
} from "react-admin";
import { sanitizeListRestProps } from "ra-core";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import { listTopFilter, StyledList } from "../../../themes/styles";
import CustomPagination from "../../../components/common/CustomPagination";
import { EActiveState } from "../../../types/enums/active-state.enum";
import { reformatToAcceptableByListComponent } from "../../../utils/delivery/rampsHelper";
import { ITicketAttributeOption } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import { ITickerUser } from "../../../types/interfaces/helpdesk/ticket/ticket.interface";
import { IRamp } from "../../../types/interfaces/delivery-zone/ramp.interface";

export interface IRampsGridRow {
  id: string;
  value: string | ITicketAttributeOption | undefined;
  active: string;
  name: string;
  createdAt: number;
  createdBy: ITickerUser;
}

const initialPagination = { page: 1, perPage: 10 };

const RampsPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const RampsListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const RampsListView = () => {
  const { data, page, perPage } = useListContext();
  const [list, setList] = useState<IRampsGridRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const listContext = useList({
    data: list,
    sort: { field: "id", order: "DESC" },
    isLoading,
    ...initialPagination,
    perPage,
  });

  useEffect(() => {
    if (data) {
      setList(reformatToAcceptableByListComponent(data));
      setIsLoading(false);
    }
  }, [data, page, perPage]);

  return (
    <SkeletonLoaderList>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false} rowClick="edit">
          <TextField source="name" label="app.delivery_zone.ramps.name" />
          <FunctionField
            label="app.auth.active"
            sortable={false}
            render={(record: any) => {
              return record.active === EActiveState.ACTIVE ? (
                <CheckIcon htmlColor="#00A044" />
              ) : (
                <CloseIcon htmlColor="#EC5B56" />
              );
            }}
          />
          <DateField
            source="createdAt"
            showTime={true}
            label="app.helpdesk.createdAt"
          />
          <FunctionField
            label="app.delivery_zone.ramps.createdBy"
            render={(record: IRamp) => {
              if (!record.createdBy) {
                return "-";
              }

              return (
                <span>{`${record.createdBy.firstName} ${record.createdBy.lastName}`}</span>
              );
            }}
          />
        </Datagrid>
      </ListContextProvider>
    </SkeletonLoaderList>
  );
};

const RampsList = () => {
  useAuthenticated();

  return (
    <StyledList
      pagination={<RampsPagination />}
      actions={<RampsListTopToolbar />}
      empty={false}
      className="styled-list"
      disableSyncWithLocation={true}
    >
      <RampsListView />
    </StyledList>
  );
};

export default RampsList;
