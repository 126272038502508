import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { useLogin, useNotify, useTranslate } from "react-admin";
import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import {
  AuthPageContainer,
  LoginFormContainer,
  LoginFormTitle,
} from "../../themes/styles";
import LogoIcon from "../icons/LogoIcon";
import { UnauthenticatedTopic } from "../../types/enums/unauthenticated-topic.enum";
import { CodeService } from "../../api/CodeService";
import { AuthService } from "../../api/AuthService";
import { OrganizationManager } from "../../constants/OrganizationManager";
import { useOrganizationLogo } from "../../hooks/useOrganizationLogo";
import ResetPasswordForm from "./ResetPasswordForm";
import LoginPageForm from "./LoginPageForm";

interface IFormValues {
  email: string;
  password: string;
}

const LogoPlaceholder = () => <div style={{ width: 110, height: 60 }} />;

const ValidationLoader = () => {
  const trans = useTranslate();

  return (
    <AuthPageContainer>
      <LogoIcon width={110} height={60} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={3}
        mt={4}
      >
        <CircularProgress size={40} />
        <Typography variant="body1" color="textSecondary">
          {trans("app.auth.organization_validating_ongoing")}
        </Typography>
      </Box>
    </AuthPageContainer>
  );
};

const LoginPage = () => {
  const login = useLogin();
  const [resetPasswordProcess, setResetPasswordProcess] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetPasswordData, setResetPasswordData] = useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const translate = useTranslate();
  const notify = useNotify();
  const formikRef = useRef<FormikProps<IFormValues> | null>(null);
  const { organizationName } = useParams();
  const navigate = useNavigate();
  const [isValidating, setIsValidating] = useState(true);
  const [organizationExists, setOrganizationExists] = useState(true);
  const {
    logoUrl,
    isLoading: isLogoLoading,
    fetchLogo,
  } = useOrganizationLogo();

  useEffect(() => {
    const validateOrg = async () => {
      if (!organizationName) {
        navigate("/login");
        return;
      }

      const isValid =
        await OrganizationManager.getInstance().validateOrganization(
          organizationName,
        );

      if (!isValid) {
        setOrganizationExists(false);
      } else {
        const orgId = OrganizationManager.getInstance().getOrgId();
        if (orgId) {
          await fetchLogo(orgId);
        }
      }

      setIsValidating(false);
    };

    validateOrg();
  }, [organizationName]);

  const handleLogin = useCallback(
    ({
      email,
      password,
      orgId,
    }: {
      email: string;
      password: string;
      orgId: string;
    }) => {
      login({ email, password, orgId }).catch((e) => {
        notify(e, { type: "error" });
      });
    },
    [login, notify],
  );

  const handleResetPassword = async (data: {
    email: string;
    password: string;
    orgId: string;
  }) => {
    setIsLoading(true);
    const newData = {
      email: data.email,
      topic: UnauthenticatedTopic.RESET_PASSWORD,
      groupId: data.orgId,
    };
    try {
      await CodeService.unauthenticatedUser(newData);
      setResetPasswordData(data);
      setShowConfirmation(true);
    } catch (e) {
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
          autoHideDuration: 100000,
        },
      );
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCodeResend = useCallback(async () => {
    try {
      await CodeService.unauthenticatedUser({
        email: resetPasswordData.email,
        topic: UnauthenticatedTopic.RESET_PASSWORD,
        groupId: OrganizationManager.getInstance().getOrgId(),
      });
    } catch (e) {
      console.log(e);
    }
  }, [resetPasswordData.email]);

  const resetFormState = () => {
    if (formikRef.current) {
      formikRef.current.setValues({
        email: "",
        password: "",
      });
      formikRef.current.setErrors({
        email: undefined,
        password: undefined,
      });
      formikRef.current.setTouched({
        email: false,
        password: false,
      });
    }
  };

  const onCodeSubmit = async (data: { code: string }) => {
    const newData = {
      ...data,
      ...resetPasswordData,
      groupId: localStorage.getItem("orgId") as string,
    };
    try {
      setIsLoading(true);
      await AuthService.resetPassword(newData);
      setShowConfirmation(false);
      setResetPasswordProcess(false);
      resetFormState();
      notify(translate("app.notifications.success.password_changed"), {
        type: "success",
      });
    } catch (e) {
      console.log(e);
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResettingProcess = useCallback(() => {
    resetFormState();
    setResetPasswordProcess(!resetPasswordProcess);
  }, [resetPasswordProcess]);

  const formTitle = useMemo(() => {
    if (showConfirmation) {
      return "app.auth.login.confirm_changes";
    }

    if (resetPasswordProcess) {
      return "app.auth.login.reset_password";
    }

    return "app.auth.login.title";
  }, [resetPasswordProcess, showConfirmation]);

  const renderTitle = () => {
    if (!resetPasswordProcess && !showConfirmation) {
      return (
        <>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              mb: 2,
            }}
          >
            {OrganizationManager.getInstance().getOrgDisplayName()}
          </Typography>
          <div data-testid="formTitle">
            <LoginFormTitle>{translate(formTitle)}</LoginFormTitle>
          </div>
        </>
      );
    }

    return (
      <div data-testid="formTitle">
        <LoginFormTitle>{translate(formTitle)}</LoginFormTitle>
      </div>
    );
  };

  if (isValidating) {
    return <ValidationLoader />;
  }

  if (!organizationExists) {
    return (
      <AuthPageContainer>
        <LogoIcon width={110} height={60} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={3}
          mt={4}
        >
          <Typography variant="h5" color="error">
            {translate("app.auth.organization_not_found_title")}
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center">
            {translate("app.auth.organization_not_found_description", {
              name: organizationName,
            })}
          </Typography>
        </Box>
      </AuthPageContainer>
    );
  }

  return (
    <AuthPageContainer>
      {isLogoLoading ? (
        <LogoPlaceholder />
      ) : logoUrl ? (
        <img
          src={logoUrl}
          alt="Organization Logo"
          style={{
            height: "60px",
            maxWidth: "200px",
            objectFit: "contain",
          }}
        />
      ) : (
        <LogoIcon width={110} height={60} />
      )}
      <LoginFormContainer>
        <>
          {renderTitle()}
          {showConfirmation ? (
            <>
              <ResetPasswordForm
                onCodeSubmit={onCodeSubmit}
                onCodeResend={onCodeResend}
                isLoading={isLoading}
              />
            </>
          ) : (
            <>
              <LoginPageForm
                isLoading={isLoading}
                resetPasswordProcess={resetPasswordProcess}
                handleLogin={handleLogin}
                handleResetPassword={handleResetPassword}
                formikRef={formikRef}
              />

              <Box style={{ textAlign: "center" }} mt={1}>
                <ButtonBase
                  aria-label="resetPasswordButton"
                  onClick={() => handleResettingProcess()}
                  style={{
                    fontSize: "14px",
                    color: "#848484",
                  }}
                >
                  {resetPasswordProcess
                    ? "Login"
                    : translate("app.auth.login.forgot_password")}
                </ButtonBase>
              </Box>
            </>
          )}
        </>
      </LoginFormContainer>
    </AuthPageContainer>
  );
};

export default LoginPage;
