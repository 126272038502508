import React from "react";
import {
  BooleanInput,
  CreateButton,
  Datagrid,
  EmailField,
  Filter,
  FilterProps,
  FunctionField,
  ListToolbarProps,
  TextField,
  TopToolbar,
  useAuthenticated,
} from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { sanitizeListRestProps } from "ra-core";
import { IUser } from "../../../types/interfaces/user.interface";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { listTopFilter, StyledList } from "../../../themes/styles";
import CustomInputWrapper from "../../../components/common/CustomInputWrapper";

const UsersListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <CustomInputWrapper
      source="search"
      alwaysOn
      resettable
      startIcon={<SearchIcon color="disabled" fontSize="large" />}
      placeholder="app.auth.search"
      title="app.auth.users"
    />
    <BooleanInput
      name="removed"
      source="removed"
      label="app.auth.removed"
      alwaysOn
    />
    <BooleanInput
      name="active"
      source="active"
      label="app.auth.active"
      alwaysOn
    />
  </Filter>
);

const UsersListView = () => {
  const userRowStyle = (record: IUser) => ({
    opacity: record.removed ? 0.3 : 1,
  });

  return (
    <SkeletonLoaderList>
      <Datagrid bulkActionButtons={false} rowClick="show" rowSx={userRowStyle}>
        <TextField source="firstName" label="app.auth.name" sortable={false} />
        <TextField
          source="lastName"
          label="app.auth.surname"
          sortable={false}
        />
        <TextField
          source="phoneNumber"
          label="app.auth.phoneNumber"
          sortable={false}
        />
        <EmailField source="email" label="app.auth.email" sortable={false} />
        <FunctionField
          label="app.auth.active"
          sortable={false}
          render={(record: IUser) => {
            return record.active ? (
              <CheckIcon htmlColor="#00A044" />
            ) : (
              <CloseIcon htmlColor="#EC5B56" />
            );
          }}
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const UserListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const UsersListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const UsersList = () => {
  useAuthenticated();
  return (
    <StyledList
      filters={<UsersListFilter />}
      pagination={<UserListPagination />}
      actions={<UsersListTopToolbar />}
      empty={false}
      className="styled-list"
      filterDefaultValues={{ active: true }}
      disableSyncWithLocation={true}
    >
      <UsersListView />
    </StyledList>
  );
};

export default UsersList;
