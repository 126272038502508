import React from "react";
import { InputProps, email, Validator } from "react-admin";
import { StyledTextInput } from "../../themes/styles";

interface IEmailInputProps extends InputProps {
  validate: Validator[];
}

const EmailInput: React.FC<IEmailInputProps> = (props) => {
  const { validate, ...rest } = props;

  const validators = [email(), ...(validate || [])];

  return <StyledTextInput {...rest} validate={validators} />;
};

export default EmailInput;
