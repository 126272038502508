import React, { useEffect, useState } from "react";
import { required, useLocaleState, Button, useTranslate } from "react-admin";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconCancel from "@mui/icons-material/Cancel";
import IconSave from "@mui/icons-material/Save";
import { ITicketAttributeOption } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import {
  buttons,
  StyledSelect,
  StyledTextInput,
} from "../../../../themes/styles";
import { TAttributeInputProps } from "./AttributeInput";

const TicketAttributeSelectInput = ({
  source,
  label,
  choices,
  isRequired,
  isSavingNewValues,
}: Omit<TAttributeInputProps, "inputType">) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const [availableChoices, setAvailableChoices] = useState<
    ITicketAttributeOption[]
  >([]);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (choices) {
      choices.sort(
        (a: ITicketAttributeOption, b: ITicketAttributeOption) =>
          a.position - b.position,
      );
    }
    setAvailableChoices(choices || []);
  }, []);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const newOption = {
      id: formJson.value,
      value: {
        en: formJson.value,
        pl: formJson.value,
      },
      position: choices?.length || 10,
    };
    setAvailableChoices((avlbChoices) => avlbChoices.concat([newOption]));
    setOpen(false);
  };

  return (
    <Box style={{ display: "flex" }}>
      <StyledSelect
        source={source}
        label={label}
        choices={availableChoices || []}
        defaultValue={""}
        optionText={(choice) =>
          choice.value[locale as keyof typeof choice.value]
        }
        variant="outlined"
        style={{ width: "100%" }}
        validate={isRequired ? required() : []}
      />
      {isSavingNewValues && (
        <IconButton color="primary" onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit,
        }}
      >
        <DialogTitle>{translate("app.helpdesk.add_new_option")}</DialogTitle>
        <DialogContent>
          <Box style={{ padding: "10px 0" }}>
            <StyledTextInput
              required
              source="value"
              label="New select item"
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Button
            onClick={() => setOpen(false)}
            label="ra.action.cancel"
            sx={buttons.darkButton}
          >
            <IconCancel />
          </Button>
          <Button label="ra.action.save" type="submit" sx={buttons.whiteButton}>
            <IconSave />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TicketAttributeSelectInput;
