import {
  required,
  useAuthenticated,
  useRedirect,
  useResourceDefinition,
} from "ra-core";
import { Edit, SimpleForm } from "react-admin";
import { AxiosRequestConfig } from "axios";
import { useParams } from "react-router";
import { ReactElement } from "react";
import { StyledSelect, StyledTextInput } from "../../../themes/styles";
import { ETaskType } from "../../../types/enums/schedule.enums";
import { ConfigManager } from "../../../constants/ConfigManager";
import { apiRequest } from "../../../api/client";
import { IScheduleResourceOptions } from "../../../types/interfaces/schedule.interfaces";
import { OrganizationManager } from "../../../constants/OrganizationManager";

const ScheduleEdit = (): ReactElement => {
  useAuthenticated();
  const { id } = useParams<{ id: string }>();
  const redirect = useRedirect();
  const { options } = useResourceDefinition<IScheduleResourceOptions>();

  const statusChoices = [
    { id: "active", name: "app.schedule.list.statuses.active" },
    { id: "inactive", name: "app.schedule.list.statuses.inactive" },
  ];

  const EditTask = async (data: Record<string, any>): Promise<void> => {
    data = {
      name: data.name,
      description: data.description ? data.description : undefined,
      active: data.active,
      type: data.type,
      ...(data.type === ETaskType.OneTime &&
        data.oneTime?.date && {
          oneTime: {
            date: data.oneTime.date,
          },
        }),
      ...(data.type === ETaskType.Recurring &&
        data.recurring?.cron && {
          recurring: {
            cron: data.recurring.cron,
          },
        }),
      timeZone: data.timeZone,
    };

    try {
      const orgId = OrganizationManager.getInstance().getOrgId();
      const config: AxiosRequestConfig = {
        method: "POST",
        url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${options?.module}/admin/${orgId}/schedule/tasks/${id}`,
        data,
      };
      await apiRequest(config);
      redirect("list", `/${options?.module}/admin/group/schedule/tasks`);
    } catch (e) {
      throw new Error("Failed to update task, please try again later");
    }
  };

  return (
    <Edit redirect="list" mutationMode="pessimistic">
      <SimpleForm sx={{ paddingTop: "20px" }} onSubmit={EditTask}>
        <StyledTextInput
          source="name"
          label="app.schedule.form.fields.name"
          validate={[required()]}
        />
        <StyledTextInput
          source="description"
          label="app.schedule.form.fields.description"
          multiline
        />
        <StyledSelect
          source="active"
          label="app.schedule.list.status"
          choices={statusChoices}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ScheduleEdit;
