import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../constants/ConfigManager";
import { IOrganizationAdmin } from "../types/interfaces/organization.interface";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import { apiRequest } from "./client";

const getOrganizationInfo = async <T>(groupId: string) => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/organization/public/${groupId}`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getOrganizationsList = async <T>() => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/organization/public`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getOrganizationLogo = async <T>(orgId: string) => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/organization/public/${orgId}/logo`,
      responseType: "blob",
      headers: {
        "Content-Type": "image/jpeg",
      },
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getOrganizationAdminInfo = async (
  orgId: string,
): Promise<IBasicResponse<IOrganizationAdmin>> => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/organization/admin/${orgId}/organization`,
    };
    return await apiRequest(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const OrganizationService = {
  getOrganizationInfo,
  getOrganizationsList,
  getOrganizationLogo,
  getOrganizationAdminInfo,
};
