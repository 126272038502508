import React from "react";
import {
  CreateButton,
  Datagrid,
  Filter,
  FilterProps,
  FunctionField,
  ListToolbarProps,
  TextField,
  TopToolbar,
  useAuthenticated,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { ColorField } from "react-admin-color-picker";
import { sanitizeListRestProps } from "ra-core";
import {
  listTopFilter,
  StyledList,
  StyledSelect,
} from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { ITicketStatus } from "../../../types/interfaces/helpdesk/ticket/ticket-status.interfaces";

const StatusesPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const StatusesListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <StyledSelect
      alwaysOn
      source="active"
      className="list-select-input"
      label="app.auth.active"
      choices={[
        { id: "active", name: "app.helpdesk.active" },
        { id: "all", name: "app.helpdesk.all" },
      ]}
      variant="outlined"
    />
  </Filter>
);

const StatusesListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const StatusesListView = () => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const rowStyle = (record: ITicketStatus) => ({
    opacity: ["inactive", "archived"].includes(record.active) ? 0.3 : 1,
  });

  return (
    <SkeletonLoaderList>
      <Datagrid bulkActionButtons={false} rowClick="show" rowSx={rowStyle}>
        <TextField source={`name.${locale}`} label="app.auth.name" />
        <ColorField source="color" label="app.helpdesk.color" />
        <FunctionField
          label="Status"
          render={(record: ITicketStatus) => (
            <span>{translate(`app.helpdesk.status.${record.active}`)}</span>
          )}
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const StatusesList = () => {
  useAuthenticated();

  return (
    <StyledList
      pagination={<StatusesPagination />}
      actions={<StatusesListTopToolbar />}
      filters={<StatusesListFilter />}
      empty={false}
      className="styled-list"
      queryOptions={{ meta: { active: "active" } }}
      disableSyncWithLocation={true}
    >
      <StatusesListView />
    </StyledList>
  );
};

export default StatusesList;
