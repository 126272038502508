import React from "react";
import {
  CreateButton,
  Datagrid,
  Filter,
  FilterProps,
  FunctionField,
  Identifier,
  ListToolbarProps,
  RaRecord,
  TextField,
  TopToolbar,
  useAuthenticated,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { sanitizeListRestProps } from "ra-core";
import {
  listTopFilter,
  StyledList,
  StyledSelect,
} from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { ITicketAttribute } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";

const AttributePagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const AttributeListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <StyledSelect
      alwaysOn
      source="active"
      className="list-select-input"
      label="app.attributes.active"
      choices={[
        { id: "active", name: "app.attributes.active" },
        { id: "all", name: "app.attributes.all" },
      ]}
      variant="outlined"
    />
  </Filter>
);

const AttributeListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const AttributeListView = () => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const rowStyle = (record: ITicketAttribute) => ({
    opacity:
      ["inactive", "archived"].includes(record.active) || !record.isRemovable
        ? 0.3
        : 1,
  });

  const attributeRowClick = (_: Identifier, __: string, record: RaRecord) =>
    record.isRemovable ? "edit" : false;

  return (
    <SkeletonLoaderList>
      <Datagrid
        bulkActionButtons={false}
        rowClick={attributeRowClick}
        rowSx={rowStyle}
      >
        <TextField source={`name.${locale}`} label="app.attributes.name" />
        <FunctionField
          label="app.attributes.type"
          render={(record: ITicketAttribute) => {
            const typeKey = record.type.replaceAll("-", "_");

            return (
              <span>{translate(`app.attributes.attribute.${typeKey}`)}</span>
            );
          }}
        />
        <FunctionField
          label="Status"
          render={(record: ITicketAttribute) => (
            <span>
              {translate(`app.attributes.attribute.${record.active}`)}
            </span>
          )}
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const AttributeList = () => {
  useAuthenticated();

  return (
    <StyledList
      pagination={<AttributePagination />}
      actions={<AttributeListTopToolbar />}
      filters={<AttributeListFilter />}
      empty={false}
      className="styled-list"
      disableSyncWithLocation={true}
    >
      <AttributeListView />
    </StyledList>
  );
};

export default AttributeList;
