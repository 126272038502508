import React from "react";
import { Resource } from "react-admin";
import DeliveryIcon from "../../components/icons/DeliveryIcon";
import { EResource, getResourceUrl } from "../../utils/resourcesHelper";
import { Roles } from "../../types/enums/roles";
import deliveryZoneAttributesResource from "./attributes";
import reservationAttributesResource from "./reservation-attributes";
import rampsResource from "./ramps";

const deliveryZone = (
  permissions: string[] | undefined,
  isModuleEnabled = false,
) => {
  const resources = [];
  const deliveryZoneAttributesUrl = "delivery/admin/group/ramps-attributes";
  const reservationAttributesUrl =
    "delivery/admin/group/reservations-attributes";
  const rampsUrl = getResourceUrl(EResource.RAMPS_LIST);

  if (isModuleEnabled) {
    if (permissions && permissions.includes(Roles.ROLE_MODULE_DELIVERY_ADMIN)) {
      resources.push(
        <Resource
          {...deliveryZoneAttributesResource}
          name={deliveryZoneAttributesUrl}
          key={deliveryZoneAttributesUrl}
          options={{
            label: "app.delivery_zone.ramp_attributes",
            menuParent: "delivery-zone-parent",
            sectionKey: "attributes-section",
            sectionName: "app.system.system_building",
          }}
        />,
      );

      resources.push(
        <Resource
          {...reservationAttributesResource}
          name={reservationAttributesUrl}
          key={reservationAttributesUrl}
          options={{
            label: "app.delivery_zone.reservation_attributes",
            menuParent: "delivery-zone-parent",
            sectionKey: "attributes-section",
            sectionName: "app.system.system_building",
          }}
        />,
      );

      resources.push(
        <Resource
          {...rampsResource}
          name={rampsUrl}
          key={rampsUrl}
          options={{
            label: "app.delivery_zone.ramps.title",
            menuParent: "delivery-zone-parent",
            sectionKey: "ramps-section",
            sectionName: "app.system.system_building",
          }}
        />,
      );
    }

    resources.push(
      <Resource
        key="delivery-zone-parent"
        name="delivery-zone-parent"
        icon={DeliveryIcon}
        options={{
          label: "app.delivery_zone.section_name",
          isMenuParent: true,
          sectionKey: "delivery-zone-parent",
          sectionName: "app.system.system_building",
        }}
      />,
    );
  }

  return resources;
};

export default deliveryZone;
