import { AxiosRequestConfig } from "axios";
import { OrganizationManager } from "../../constants/OrganizationManager";
import { ConfigManager } from "../../constants/ConfigManager";
import { apiRequest } from "../client";

const updateTenant = async <T>(id: string | undefined, data: any) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/organization/admin/${orgId}/tenants/${id}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const createTenant = async <T>(data: any) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/organization/admin/${orgId}/tenants`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const tenantsService = {
  createTenant,
  updateTenant,
};
