import React from "react";
import {
  Create,
  SimpleForm,
  email,
  required,
  BooleanInput,
  ListToolbarProps,
  useAuthenticated,
  useRedirect,
  useNotify,
  useTranslate,
} from "react-admin";
import { Toolbar } from "@mui/material";
import {
  StyledArraySelect,
  StyledSaveButton,
  StyledTextInput,
} from "../../../themes/styles";
import { userService } from "../../../api/UserService";

const UsersCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const UsersCreate = () => {
  useAuthenticated();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async (values: any) => {
    try {
      await userService.createUser(values);
      notify(translate("app.auth.user_created"), { type: "success" });
      redirect("list", "admin/group/users");
    } catch (error) {
      if (error.subErrors) {
        const errorMessages = error.subErrors
          .map((subError: any) => subError.message)
          .join("\n");
        notify(errorMessages, { type: "error", multiLine: true });
      } else if (error.translatedMessage) {
        notify(error.translatedMessage, { type: "error" });
      } else {
        notify("ra.notification.http_error", { type: "error" });
      }
    }
  };

  return (
    <Create redirect="list">
      <SimpleForm toolbar={<UsersCreateFormToolbar />} onSubmit={handleSubmit}>
        <StyledTextInput
          variant="outlined"
          name="email"
          source="email"
          label="app.auth.email"
          validate={email()}
        />

        <StyledTextInput
          variant="outlined"
          name="firstName"
          source="firstName"
          label="app.auth.name"
          validate={required()}
        />

        <StyledTextInput
          variant="outlined"
          name="lastName"
          source="lastName"
          label="app.auth.surname"
          validate={required()}
        />

        <StyledTextInput
          variant="outlined"
          name="phoneNumber"
          source="phoneNumber"
          label="app.auth.phoneNumber"
          validate={required()}
        />

        <BooleanInput
          name="active"
          source="active"
          variant="outlined"
          label="app.auth.active"
        />

        <StyledArraySelect
          variant="outlined"
          name="roles"
          choices={[
            { id: "ADMIN", name: translate("app.auth.roles_list.admin") },
            {
              id: "DELIVERY_ADMIN",
              name: translate("app.auth.roles_list.delivery_admin"),
            },
            {
              id: "HELPDESK_ADMIN",
              name: translate("app.auth.roles_list.helpdesk_admin"),
            },
            {
              id: "PARKING_ADMIN",
              name: translate("app.auth.roles_list.parking_admin"),
            },
            {
              id: "PARKING_MANAGER",
              name: translate("app.auth.roles_list.parking_manager"),
            },
            { id: "EMPLOYEE", name: translate("app.auth.roles_list.employee") },
            {
              id: "HELPDESK_ASSIGNEE",
              name: translate("app.auth.roles_list.helpdesk_assignee"),
            },
            { id: "CUSTOMER", name: translate("app.auth.roles_list.customer") },
          ]}
          label="app.auth.roles"
          source="roles"
        />
      </SimpleForm>
    </Create>
  );
};

export default UsersCreate;
