import React, { ReactElement } from "react";
import { Resource } from "react-admin";
import ParkingIcon from "../../components/icons/ParkingIcon";
import { Roles } from "../../types/enums/roles";
import { TParkingSettings } from "../../hooks/useParkingSettings";
import scheduleResource from "../schedule/template";
import vehiclesResources from "./vehicles";
import transactionsResources from "./transactions";
import refundsResources from "./refunds";
import reservationsResources from "./reservations";
import CreateReportHandler from "./schedule/handlers/CreateReportHandler";

const parking = (
  permissions: string[] | undefined,
  settings: TParkingSettings | undefined,
): ReactElement[] => {
  const resources = [];

  const url = "parking/admin/group/vehicles";
  const transactionsUrl = "parking/admin/group/transactions";
  const refundsUrl = "parking/admin/group/refunds";
  const reservationsUrl = "parking/admin/group/reservations";
  const scheduleUrl = "parking/admin/group/schedule/tasks";

  if (permissions && permissions.includes(Roles.ROLE_MODULE_PARKING_ADMIN)) {
    if (settings?.vehicles?.enabled) {
      resources.push(
        <Resource
          {...vehiclesResources}
          name={url}
          key={url}
          options={{
            label: "app.parking.vehicles",
            menuParent: "vehicles-parent",
            isMenuParent: false,
            sectionKey: "vehicles-section",
            sectionName: "app.system.system_building",
          }}
        />,
      );
    }

    if (settings?.transactions?.enabled) {
      resources.push(
        <Resource
          {...transactionsResources}
          name={transactionsUrl}
          key={transactionsUrl}
          options={{
            label: "app.parking.transactions",
            menuParent: "vehicles-parent",
            isMenuParent: false,
            sectionKey: "vehicles-section",
            sectionName: "app.system.system_building",
          }}
        />,
      );

      resources.push(
        <Resource
          {...refundsResources}
          name={refundsUrl}
          key={refundsUrl}
          options={{
            label: "app.parking.refunds",
            menuParent: "vehicles-parent",
            isMenuParent: false,
            sectionKey: "vehicles-section",
            sectionName: "app.system.system_building",
          }}
        />,
      );
    }

    if (settings?.reservations?.enabled) {
      resources.push(
        <Resource
          {...reservationsResources}
          name={reservationsUrl}
          key={reservationsUrl}
          options={{
            label: "app.parking.reservations",
            menuParent: "vehicles-parent",
            isMenuParent: false,
            sectionKey: "vehicles-section",
            sectionName: "app.system.system_building",
          }}
        />,
      );
    }

    resources.push(
      <Resource
        key="vehicles-parent"
        name="vehicles-parent"
        icon={ParkingIcon}
        options={{
          label: "Parking",
          isMenuParent: true,
          sectionKey: "vehicles-section",
          sectionName: "app.system.system_building",
        }}
      />,
    );

    resources.push(
      <Resource
        {...scheduleResource}
        name={scheduleUrl}
        key={scheduleUrl}
        options={{
          label: "app.schedule.label",
          menuParent: "vehicles-parent",
          sectionKey: "schedule-section",
          sectionName: "app.system.system_building",
          module: "parking",
          handlers: {
            CreateReport: <CreateReportHandler />,
          },
        }}
      />,
    );
  }

  return resources;
};

export default parking;
