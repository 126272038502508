const appEnglishMessages = {
  "resources.maps/buildings.fields.properties.name": "Name",
  "resources.maps/map-levels.fields.properties.name": "Name",
  "resources.maps/map-levels.fields.properties.short_name": "Short name",
  "resources.marketing/landing-page-media-item.name": "Media item",
  app: {
    auth: {
      parent: "Users",
      users: "Users",
      groups: "Groups",
      name: "Name",
      path: "Path",
      code: "Code",
      access_account_limit: "HID Account Limit",
      remove_marketplace: "Remove marketplace",
      choose_marketplace: "Choose marketplace",
      add_marketplace: "Add marketplace",
      seller_profile: "Seller profile",
      empty: "Empty",
      image: "Image",
      status_changed_correctly: "The status has been changed correctly",
      consent_templates: "Consents",
      consents: "Given consents",
      title: "Title",
      onboarding_pages: "Onboarding Pages",
      order: "Order",
      field_is_required: "This field is required",
      content: "Content",
      required: "Required",
      optIn: "OptIn",
      active: "Active",
      hid_accounts_limit: "HID Accounts Limit",
      remove_hid_account: "Remove account HID",
      email: "Email",
      phoneNumber: "Phone number",
      removed: "Removed",
      surname: "Surname",
      first_name: "Name",
      email_verified: "Email Verified",
      has_hid_account: "Has HID Account",
      summary: "Summary",
      roles: "Roles",
      role: "Role",
      add_role: "Add Role",
      access_cards: "Access cards",
      card_number: "Card Number",
      device_description: "Device Description",
      description: "Description",
      created_at: "Created at",
      updated_at: "Updated at",
      remove: "Remove",
      activate: "Activate",
      deactivate: "Deactivate",
      activate_deactivate: "Activate/Deactivate",
      password_reset: "Password Reset",
      change_group: "Change Group",
      add_hid_account: "Add HID Account",
      remove_role: "Remove role",
      counterId: "Group parking id",
      security_role: "Security",
      supplier_role: "Supplier",
      value: "Value",
      login: {
        confirm_changes: "Confirm changes",
        reset_password: "Reset password",
        title: "Log in to your account",
        code: "Code",
        password: "Password",
        forgot_password: "Forgot password",
        new_password: "New password",
        confirm_password: "Confirm password",
        passwords_must_match: "Passwords must match",
        password_required: "Password is required",
        email_required: "Email is required",
        email_invalid: "Invalid email",
      },
      next: "Next",
      back: "Back",
      delete_confirm: "Are you sure you want to delete user %{name}",
      confirmation: "Confirmation",
      search: "Search",
      organization: "Organization",
      choose_organization: "Choose organization",
      user_updated: "User was updated successfully",
      user_created: "User was successfully created",
      organization_validating_ongoing: "Validating of organization...",
      organization_not_found_title: "Organization Not Found",
      organization_not_found_description:
        "The organization „%{name}” does not exist or is not available. Please check the URL and try again or refresh page.",
      go_to_main_page: "Go to Main Page",
      organization_required_title: "Organization Required",
      organization_required_description:
        "Please access this page through your organization's specific URL. Contact your administrator if you need assistance.",
      roles_list: {
        admin: "Admin",
        customer: "Customer",
        employee: "Employee",
        delivery_admin: "Delivery Admin",
        helpdesk_admin: "Helpdesk Admin",
        parking_admin: "Parking Admin",
        parking_manager: "Parking Manager",
        helpdesk_assignee: "Helpdesk Assignee",
        module_delivery_admin: "Delivery Admin",
        module_helpdesk_admin: "Helpdesk Admin",
        module_parking_admin: "Parking Admin",
        module_parking_manager: "Parking Manager",
        module_helpdesk_assignee: "Helpdesk Assignee",
      },
    },
    mobile: {
      mobile_modules: "Mobile modules",
      mobile_parent: "Mobile",
      advertisingText: "Advertising text",
      add_level: "Add level",
      add_path: "Add a path",
      module: "Module",
      add_page: "Add page",
      mobile_module_tags: "Tags",
      name: "Name",
      type: "Type",
      order: "Order",
      isActive: "Active",
      onlyForLoggedIn: "Only For LoggedIn",
      createdAt: "Created at",
      updatedAt: "Updated at",
      calendar: "Calendar",
      manual: "Manual",
      accesses: "Accesses",
      role: "Role",
      group: "Group",
      url: "URL",
      landing_page: "Landing page",
      faq: "FAQ",
      short_description: "Short description",
      duration: "Duration",
      station: "Station",
      default: "Default in mobile app",
      description: "Description",
      web_frame: "Web frame",
      module_updated_success: "Module was successfully updated",
      module_general: "General Information",
      pages: "Pages",
      edit: "Edit",
      module_was_created: "Module was created",
      active: "Active",
      icon: "Icon",
      WEB_FRAME: "Web frame",
      inactive: "Inactive",
      archived: "Archived",
      position: "Position",
    },
    parking: {
      parking: "Parking",
      plate_number: "Plate number",
      model: "Model",
      color: "Color",
      brand: "Brand",
      vehicles: "Vehicles",
      registered_vehicles: "Registered vehicles",
      extend_time: "Extend time",
      extend: "Extend",
      extend_success: "Success",
      extend_time_for: "Extend time for",
      in_minutes: "In minutes",
      from_date: "Created from",
      transactions: "Transactions",
      transaction: "Transaction",
      issuer: "Issuer",
      refund_creating: "Refund creating",
      other: "Other",
      requested_by_customer: "Requested by customer",
      duplicate: "Duplicate",
      user_first_name: "User's first name",
      user_last_name: "User's last name",
      user_email: "User's email",
      reason: "Reason",
      to_amount: "Amount to",
      from_amount: "Amount from",
      vehicles_not_found: "No user vehicles found",
      description_of_reason: "Description of reason",
      failure_reason: "Failure reason",
      create: "Create",
      transaction_statuses: {
        processing: "Processing",
        failed: "Failed",
        succeeded: "Succeeded",
        canceled: "Canceled",
      },
      to_date: "Created before",
      currency: "Currency",
      created_at: "Created at",
      user_phone_number: "User's phone number",
      user: "User",
      refund: "Refund",
      lastTransactionAt: "Last transaction date",
      transactionsCount: "Amount of transactions",
      vehicles_amount: "Amount of registered vehicles",
      refund_status: "Refund status",
      refunds: "Refunds",
      payment: {
        errors: {
          invalidAmount: "Invalid amount for refund",
        },
      },
      amount: "Amount",
      refunded_amount: "Refunded amount",
      refund_was_created: "Refund was created",
      ticket: "Ticket",
      transaction_type: "Transaction type",
      transaction_status: "Transaction status",
      prepaid: {
        welcome_message: "Welcome to the parking %{name}",
        promo_message:
          "Register your car and get %{hours} free hours of parking",
        page_error_message: "Page doesn't exist",
        page_error_link: "Go to parking prepaid",
        organization_error_message:
          "The page could not be loaded, please check your internet connection and try again by clicking the link below.",
        organization_error_link: "Try again",
        register: "Register",
        second_step_title:
          "Thank you for your registration. Now you can take advantage of free %{hours} hours of parking",
        second_step_question: "How long do you want to park?",
        second_step_prolongate_message:
          "After the free %{hours} hours, you will be able to extend your parking by making a payment.",
        your_stopover_time: "Your stopover time is",
        your_plate_number: "Your registration number",
        standard_time_title: "%{hours} hours for free",
        more_time_title: "Longer (pay for ticket)",
        third_step_title: "Specify the parking time using the fields below",
        minutes: "Minutes",
        hours: "Hours",
        pay_parking_until: "You will pay for parking until",
        confirm: "Confirm",
        order_and_pay: "Order and pay",
        form_title: "Complete the form and then make the payment",
        first_name: "First name",
        last_name: "Last name",
        email: "Email",
        phone_number: "Phone number",
        sms_step_title:
          "Thank you for purchasing. You will receive confirmation of ticket purchase on the telephone number provided",
        sms_step_subtitle:
          "We will also remind you that your ticket expires and needs to be extended",
        finish: "Finish",
        no_notifications: "I don't want to receive notifications",
        make_a_payment: "Make a payment",
        pay: "Pay",
        go_to_payment: "Go to payment",
        payment_redirect_message:
          "You will soon be redirected to the payment screen. If you are not redirected, click the link below",
        price: "Price",
        accept_text:
          "By registering at the parking, I confirm that I have read and accept",
        accept_text_and: "and",
        accept_text_privacy_policy: "privacy policy",
        accept_text_parking_regulations: "regulations",
        accept_text_privacy_policy_capitalize: "Privacy Policy",
        accept_text_parking_regulations_capitalize: "Regulations",
        accept_text_parking_price_capitalize: "Price",
        accept: "Accept",
      },
      menu: {
        parking_price: "Parking price list",
        parking_regulations: "Parking regulations",
        parking_privacy_policy: "Privacy policy",
        all_parking: "All parking lots",
      },
      errors: {
        providerNotConfigured: "There is no available parking",
        time_cannot_be_zero: "Time cannot be 0",
      },
      error: {
        ticket: {
          activeAlreadyExists: "Active ticket is already exists",
        },
      },
      reservations: "Reservations",
      reservationFrom: "Reservation from",
      reservationTo: "Reservation to",
      reservation_was_canceled: "Reservation was canceled",
      reservation_was_created: "Reservation was created",
      reservation_was_updated: "Reservation was updated",
      choose_user_warning_message: "Enter at least 3 characters",
      cancel: "Cancel",
      edit: "Edit",
      validation: {
        creating_form: "You must provide a username and plate number",
      },
      schedule: {
        handlers: {
          create_report: {
            labels: {
              period: "Period",
              from: "From",
              to: "To",
              sheets: "Sheets",
              format: "Format",
              email: "Send to email",
            },
            form: {
              sheets: {
                summary: "Summary",
                customers: "Customers",
                tickets: "Tickets",
              },
              periods: {
                today: "Today",
                yesterday: "Yesterday",
                last7days: "Last 7 days",
                last30days: "Last 30 days",
                lastWeek: "Last week",
                lastMonth: "Last month",
                lastYear: "Last year",
                thisWeek: "This week",
                thisMonth: "This month",
                thisYear: "This year",
                custom: "Custom",
              },
              email_info:
                "If empty, the report will be sent to the email address of the user who created the report: %{email}",
            },
          },
        },
      },
    },
    attributes: {
      color: "Color",
      active: "Active",
      archived: "Archived",
      inactive: "Inactive",
      all: "All",
      statuses: "Statuses",
      name: "Name",
      type: "Type",
      isOptional: "Is optional?",
      isRemovable: "Is removable?",
      isSavingNewValues: "Is saving new values",
      singleLineText: "Single line text",
      multiLineText: "Multi line text",
      integer: "Integer",
      roles: "Roles",
      decimal: "Decimal",
      position: "Position",
      dateTime: "Date",
      singleSelect: "Single select",
      attribute_name_en: "Enter name of option in english",
      attribute_name_pl: "Enter name of option in polish",
      options: "Options",
      admin: "Admin",
      customer: "Customer",
      unauthenticated: "Unauthenticated",
      added: "Added",
      attribute: {
        active: "Active",
        inactive: "Inactive",
        single_line_text: "Single line text",
        multi_line_text: "Multi line text",
        status: "Status",
        integer: "Integer",
        decimal: "Decimal",
        date_time: "Datetime",
        single_select: "Single select",
        length: "Length",
        mass: "Mass",
        plate_number: "Plate number",
        phone: "Phone number",
        email: "Email",
        duration: "Duration",
        boolean: "Checkbox",
      },
    },
    delivery_zone: {
      section_name: "Delivery zone",
      ramp_attributes: "Ramp attributes",
      reservation_attributes: "Reservation attributes",
      ramps: {
        ramps_detail: "Ramps detail",
        name: "Name",
        title: "Ramps",
        active: "Active",
        inactive: "Inactive",
        createdBy: "Created by",
        form: {
          title: "Add a ramp",
        },
        ramp_deleted: "Ramp was successfully deleted",
        ramp_created: "Ramp was successfully created",
        save: "Save",
        delete: "Delete",
        close: "Cancel",
        delete_dialog_content: "Are you sure you want to delete?",
        is_ramp_active: "Is ramp active?",
        does_not_repeat: "Does not repeat",
        daily: "Daily",
        weekly: "Weekly",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        recurrence: "Recurrence",
        recurrence_end_date: "Recurrence end date",
        create_timeslot: "Create timeslot",
        edit_timeslot: "Edit timeslot",
        recurrence_end_date_required: "Recurrence end date is required",
        timeslot: {
          active: "Active?",
        },
      },
    },
    helpdesk: {
      color: "Color",
      helpdesk: "Helpdesk",
      tickets: "Tickets",
      active: "Active",
      archived: "Archived",
      inactive: "Inactive",
      all: "All",
      statuses: "Statuses",
      ticket_attributes: "Ticket attributes",
      name: "Name",
      type: "Type",
      isOptional: "Is optional?",
      isRemovable: "Is removable?",
      isSavingNewValues: "Is saving new values",
      singleLineText: "Single line text",
      multiLineText: "Multi line text",
      integer: "Integer",
      roles: "Roles",
      decimal: "Decimal",
      dateTime: "Date",
      singleSelect: "Single select",
      position: "Position",
      attribute_name_en: "Enter name of option in english",
      attribute_name_pl: "Enter name of option in polish",
      options: "Options",
      admin: "Admin",
      customer: "Customer",
      unauthenticated: "Unauthenticated",
      added: "Added",
      tickets_attachment_drop_file: "Drop your file here, max size: ",
      updated: "Updated",
      attribute_was_created: "Attribute was successfully created",
      status: {
        active: "Active",
        inactive: "Inactive",
      },
      attribute: {
        active: "Active",
        inactive: "Inactive",
        single_line_text: "Single line text",
        multi_line_text: "Multi line text",
        status: "Status",
        integer: "Integer",
        decimal: "Decimal",
        dateTime: "Datetime",
        single_select: "Single select",
      },
      workflow: {
        active: "Active",
        inactive: "Inactive",
        notConnectedRequiredNodes:
          "You need to have at lease 1 connection to initial and at least 1 to final node",
        activeMessage:
          "After click save all other workflows with the same role will turn to inactive",
        condition: "Condition to use workflow",
        conditionField: "Condition attribute",
        conditionOperator: "Condition operator",
        conditionValue: "Attribute value",
      },
      ticketsPage: {
        createTicketTitle: "Create new ticket",
        noWorkflowAvailable:
          "Could not create new ticket. Contact your administrator.",
        noWorkflowAvailableParams:
          "Could not create new ticket with given parameters. Contact your administrator.",
        noWorkflowAvailableAdmin:
          'No workflow available. Please create one first in the "Workflows" section, or by clicking this message.',
      },
      add_comment: "Add comment",
      comment: "Comment",
      comment_added: "Comment was added",
      user: "User",
      createdAt: "Created at",
      content: "Content",
      field: "Field",
      action: "Action",
      before: "Value before",
      after: "Value after",
      comments_list: "Comment list",
      ticket_details: "Tickets details",
      ticket_history: "Ticket history",
      empty_ticket_comments_list: "Comment list is empty",
      empty_ticket_history: "History is empty",
      attachment: "Attachment",
      add_attachment: "Add attachment",
      change_status: "Change status",
      add_new_option: "Add new option",
      reporter: "Reporter",
      attribute_value: "Value",
      attribute_name: "Name",
      title: "Title",
      description: "Description",
      priority: "Priority",
      assignee_to_user: "Assignee",
      choose_user: "Choose user",
      choose_vehicle: "Choose vehicle",
      date_of_change: "Date of change",
      responsible_user: "Responsible",
      created_by: "Created by",
      assign_workflow: "Assign workflow",
      assign_user: "Assign user",
      assigned_user: "Assigned user",
    },
    system: {
      system_admin: "Administation",
      system_language: "Language",
      system_profile: "Profile",
      system_notifications: "Notifications",
      system_lang_english: "English",
      system_lang_polish: "Polish",
      system_logout: "Logout",
      system_application: "Application",
      system_building_management: "Building management",
      system_facilities: "Facilities",
      system_parkings: "Parking's",
      system_entertainments: "Entertainments",
      system_marketplace: "Shopping",
      system_summary: "Summary",
      system_cancel: "Cancel",
      system_building: "Building",
    },
    notifications: {
      success: {
        status_changed_correctly: "The status has been changed correctly",
        operation_finished_successfully: "Operation completed successfully",
        hid_account_created: "The HID account has been created correctly",
        hid_account_deleted: "The HID account has been deleted correctly",
        card_hid_was_deleted: "The HID card has been removed correctly",
        sync_successfully_finished: "Synchronization completed successfully",
        request_was_sent_to_parking_system:
          "The request has been sent to the parking system",
        password_changed: "Password successfully changed",
      },
      error: {
        request_failed_with_status_code_500: "Request failed",
        error_occured: "Error occured",
        unauthorized: "Session expired",
        error: "Error",
        undefined: "An error has occurred",
        status_changed_error: "An error occurred while changing the status",
        hid_account_creation_error:
          "There was an error creating the HID account",
        hid_account_deletion_error:
          "An error occurred while deleting the HID account",
        card_hid_deletion_error:
          "An error occurred while removing the HID card",
        access_denied: "Access denied",
        unexpected_error: "An error occurred. Please try again.",
      },
      attachment: "Attachment",
    },
    validation: {
      required: "Required",
      required_field: "Required field",
      minimum_characters: "At least %{number} characters",
      maximum_characters: "Maximum is %{number} characters",
      only_numbers_allowed: "Only numbers allowed",
      email: "Invalid email",
      password_requirements:
        "The password must have min. 8 chars, a special character, number and uppercase character",
      only_numbers_and_uppercase_letters_are_allowed:
        "Only numbers and uppercase letters are allowed",
      only_letters_and_numbers: "Only letters and numbers",
      incorrect_format: "Incorrect format",
    },
    form: {
      password: "Password",
      email: "Email",
    },
    schedule: {
      schedule_parent: "Schedule",
      tasks: "Tasks",
      label: "Schedule",
      organization: {
        system: "System",
      },
      form: {
        sections: {
          general: "General",
          time: "Time",
          action: "Action",
        },
        fields: {
          id: "ID",
          createdAt: "Created at",
          updatedAt: "Updated at",
          startedAt: "Started at",
          finishedAt: "Finished at",
          timestamp: "Timestamp",
          level: "Level",
          message: "Message",
          requestId: "Request ID",
          hostname: "Hostname",
          finishStatus: "Finish status",
          createdBy: "Created by",
          name: "Name",
          description: "Description",
          type: "Type",
          time: "Time",
          timeZone: "Time zone",
          cron: "Cron",
          taskType: "Task type",
          actionType: "Action type",
          action: "Action",
          recurring: "Recurring",
          oneTime: "One time",
          upcomingExecutions: "Upcoming executions",
          date: "Date",
          every: "Every",
          year: "Year",
          month: "Month",
          week: "Week",
          day: "Day",
          hour: "Hour",
          minute: "Minute",
          dayOfWeek: "Day of week",
          dayOfMonth: "Day of month",
          monthHelper:
            "Select months in which the task will be executed, or leave empty for every month.",
          dayOfMonthHelper:
            "Select days of the month in which the task will be executed, or leave empty for every day.",
          dayOfWeekHelper:
            "Select days of the week in which the task will be executed, or leave empty for every day.",
          months: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
          },
          daysOfWeek: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
          },
        },
        emptyLogs: "No logs available",
        emptyTasks: "No tasks available",
        emptyRuns: "No runs available",
      },
      list: {
        unknown: "Unknown",
        name: "Name",
        type: "Type",
        module: "Module",
        handler: "Action",
        upcoming_run: "Upcoming run",
        status: "Status",
        organization: "Organization",
        searchPlaceholder: "Search",
        search: "Search",
        statuses: {
          active: "Active",
          inactive: "Inactive",
          all: "All",
        },
      },
      show: {
        tabs: {
          general: "General",
          payload: "Payload",
          runs: "Runs",
        },
      },
      runStatuses: {
        completed: "Completed",
        failed: "Failed",
        canceled: "Canceled",
        running: "Running",
        pending: "Pending",
      },
      handlers: {
        CreateTicket: "Create Ticket",
        CreateReport: "Create Report",
      },
      taskTypes: {
        "one-time": "One time",
        recurring: "Recurring",
      },
    },
    administration: {
      administration_parent: "Administration",
      tenants: {
        tenants: "Tenants",
        search_tenants: "Search",
        visible: "Visible",
        enabled: "Enabled",
        tenant_name: "Tenant name",
        display_name: "Display name",
        delete_confirm: "Are you sure you want to delete tenant %{name}",
        successfully_created: "Tenant was successfully created",
        successfully_updated: "Tenant was successfully updated",
        empty_list: "No tenants",
        tenant: "Tenant",
      },
    },
    common: {
      yes: "Yes",
      no: "No",
    },
  },
};

export default appEnglishMessages;
