import React, { cloneElement } from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  FunctionField,
  TextField,
  TopToolbar,
  useAuthenticated,
  useTranslate,
} from "react-admin";
import {
  listTopFilter,
  StyledList,
  StyledNumberInput,
  StyledTextInput,
} from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { IRefundItem } from "../../../types/interfaces/admin-parking/refund-item.interface";
import { parseDateToMilliseconds } from "../../../utils/timeService";

const RefundsListActions = (props: any) => {
  const { filters } = props;

  return (
    <TopToolbar sx={listTopFilter.topToolbar.rightFilters}>
      {cloneElement(filters, { context: "button" })}
    </TopToolbar>
  );
};

const RefundsListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <DateInput
      source="fromDate"
      label="app.parking.from_date"
      variant="outlined"
      parse={parseDateToMilliseconds}
    />
    <DateInput
      source="toDate"
      label="app.parking.to_date"
      variant="outlined"
      parse={parseDateToMilliseconds}
    />
    <StyledNumberInput
      source="fromAmount"
      variant="outlined"
      label="app.parking.from_amount"
    />

    <StyledNumberInput
      source="toAmount"
      variant="outlined"
      label="app.parking.to_amount"
    />

    <StyledTextInput
      source="plateNumber"
      variant="outlined"
      label="app.parking.plate_number"
    />
  </Filter>
);

const RefundsListView = () => {
  const translate = useTranslate();

  return (
    <SkeletonLoaderList>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" label="ID" sortable={false} />
        <DateField source="createdAt" label="app.parking.created_at" />
        <FunctionField
          label="app.parking.amount"
          render={(record: IRefundItem) => (record.amount / 100).toFixed(2)}
        />

        <FunctionField
          label="app.parking.reason"
          render={(record: IRefundItem) =>
            translate(`app.parking.${record.reason}`)
          }
        />
        <FunctionField
          label="app.parking.refund_status"
          render={(record: IRefundItem) =>
            translate(`app.parking.transaction_statuses.${record.status}`)
          }
        />
        <TextField
          source="reasonDetails"
          label="app.parking.description_of_reason"
          sortable={false}
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const RefundsListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const RefundsList = () => {
  useAuthenticated();
  return (
    <StyledList
      className="styled-list"
      filters={<RefundsListFilter />}
      pagination={<RefundsListPagination />}
      actions={<RefundsListActions />}
      exporter={false}
      sort={{ field: "createdAt", order: "DESC" }}
      disableSyncWithLocation={true}
    >
      <RefundsListView />
    </StyledList>
  );
};

export default RefundsList;
