import { addRefreshAuthToAuthProvider } from "ra-core";
import { AuthProvider } from "react-admin";
import { isTokenValid } from "../utils/tokenService";
import { apiRequest } from "../api/client";
import { IProfile } from "../types/interfaces/profile.interface";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import i18nProvider from "../translations/i18n-provider";
import { ConfigManager } from "../constants/ConfigManager";
import { AuthService } from "../api/AuthService";
import { IIdentity } from "../types/interfaces/identity.interface";
import { OrganizationManager } from "../constants/OrganizationManager";

const { translate } = i18nProvider;

export const refreshAuth = async () => {
  try {
    const token = localStorage.getItem("token");
    if (token && !isTokenValid(token)) {
      const data = await AuthService.refreshToken();

      localStorage.setItem("token", data?.accessToken);
      localStorage.setItem("refreshToken", data?.refreshToken);
    }

    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

const customAuthProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const { data } = await apiRequest<IBasicResponse<any>>({
        method: "POST",
        url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/auth/login`,
        data: {
          email,
          password,
          groupId: OrganizationManager.getInstance().getOrgId(),
        },
      });

      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);

      const profileResponse = await apiRequest<IBasicResponse<IProfile>>({
        method: "GET",
        url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/users/me`,
      });

      localStorage.setItem("roles", JSON.stringify(profileResponse.data.roles));

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(
        e?.response?.data?.error?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
      );
    }
  },
  getIdentity: async () => {
    try {
      const { data } = await apiRequest<IBasicResponse<IProfile>>({
        method: "GET",
        url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/users/me`,
      });

      return Promise.resolve(<IIdentity>{
        id: data.id,
        groupId: data.dealers[0].id,
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: `${data.firstName} ${data.lastName}`,
        email: data.email,
        phoneNumber: data.phoneNumber,
        avatarId: data.avatarId,
        roles: data.roles,
        groups: data.dealers.map((dealer) => ({
          id: dealer.id,
          name: dealer.name,
          displayName: dealer.displayName,
          type: dealer.type,
        })),
      });
    } catch (e) {
      return Promise.reject();
    }
  },
  getPermissions: async () => {
    const roles = localStorage.getItem("roles");
    return Promise.resolve(roles ? JSON.parse(roles) : []);
  },
  logout: () => {
    const orgName = OrganizationManager.getInstance().getOrgName();

    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("roles");

    return Promise.resolve(orgName ? `/login/${orgName}` : "/login");
  },
  checkAuth: async () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const orgName = OrganizationManager.getInstance().getOrgName();

    if (!token || !orgName) {
      return Promise.reject();
    }

    try {
      if (token && !isTokenValid(token)) {
        if (refreshToken && isTokenValid(refreshToken)) {
          const data = await AuthService.refreshToken();
          localStorage.setItem("token", data?.accessToken);
          localStorage.setItem("refreshToken", data?.refreshToken);
        } else {
          return Promise.reject();
        }
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  checkError: (error: { status?: number }) => {
    const status = error?.status;
    const orgName = OrganizationManager.getInstance().getOrgName();

    if (status === 401 || status === 403) {
      return Promise.reject(orgName ? `/login/${orgName}` : "/login");
    }
    return Promise.resolve();
  },
};

export const authProvider = addRefreshAuthToAuthProvider(
  customAuthProvider,
  refreshAuth,
);
