import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../constants/ConfigManager";
import { OrganizationManager } from "../constants/OrganizationManager";
import { apiRequest } from "./client";

const getUserList = async <T>(params?: {
  roles?: string[];
  search?: string;
  limit?: number;
}) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/admin/${orgId}/users`,
      params,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const updateUser = async <T>(id: string | undefined, data: any) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/admin/${orgId}/users/${id}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const createUser = async <T>(data: any) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/admin/${orgId}/users`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const userService = {
  getUserList,
  updateUser,
  createUser,
};
