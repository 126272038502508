import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Button,
  useLocaleState,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import { ITicket } from "../../../../types/interfaces/helpdesk/ticket/ticket.interface";
import { helpdeskAttributesService } from "../../../../api/HelpdeskAttributesService";
import { EAllowedAttributeType } from "../../../../types/enums/ticket-attribute-type.enums";
import { ITicketAttributeOption } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import { ITicketAttribute } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { buttons } from "../../../../themes/styles";
import { isDarkColor } from "../../../../utils/colorService";

const ChangeStatusSection = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [locale] = useLocaleState();
  const ticket = useRecordContext<ITicket>();
  const notify = useNotify();

  const [statusAttribute, setStatusAttribute] = useState<
    ITicketAttribute | undefined
  >(undefined);

  const handleChangeStatus = async (statusId: string) => {
    const newStatusData = {
      value: statusId,
    };

    try {
      await helpdeskAttributesService.updateTicketAttribute(
        statusAttribute?.id,
        ticket.id,
        newStatusData,
      );
      refresh();
    } catch (e) {
      notify(
        e?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  const getAttributeWithTypeStatus = () => {
    if (ticket?.attributes) {
      return ticket.attributes.find(
        (item) => item.type === EAllowedAttributeType.Status,
      );
    }
  };

  const assignStatusAttribute = async () => {
    if (ticket?.attributes) {
      const stsAttribute = getAttributeWithTypeStatus();

      setStatusAttribute(stsAttribute);
    }
  };

  useEffect(() => {
    assignStatusAttribute();
  }, [ticket]);

  const getWorkflowLabelForStatus = (currentStatusId: string) => {
    return getStatusNameByIdFromAvailableStatuses(currentStatusId);
  };

  const getStatusNameByIdFromAvailableStatuses = (id: string) => {
    const status = statusAttribute?.options?.find(
      (item: ITicketAttributeOption) => item.id === id,
    );

    if (status) {
      return status.value[locale as keyof typeof status.value];
    }

    // probably never gonna happen
    return "";
  };

  return (
    <Box>
      {statusAttribute?.options?.map((itemStatus: ITicketAttributeOption) => {
        if (itemStatus.id === statusAttribute?.value) {
          return <></>;
        }
        return (
          <Button
            sx={[
              buttons.button,
              {
                marginRight: "5px",
                backgroundColor: itemStatus?.style?.color,
                color: isDarkColor(itemStatus?.style?.color) ? "#fff" : "#000",
                "&:hover": {
                  backgroundColor: itemStatus?.style?.color,
                  border: "none",
                },
              },
            ]}
            label={getWorkflowLabelForStatus(itemStatus.id)}
            onClick={() => handleChangeStatus(itemStatus.id)}
          />
        );
      })}
    </Box>
  );
};

export default ChangeStatusSection;
