import { EOrganizationType } from "../types/enums/organization.enums";
import { ConfigManager } from "./ConfigManager";

interface IOrganization {
  id: string;
  name: string;
  displayName: string;
  logoId: string;
  enabled: boolean;
  visible: boolean;
  type: EOrganizationType;
}

export class OrganizationManager {
  private static instance: OrganizationManager;
  private organization: IOrganization | null = null;
  private listeners: Array<(orgId: string) => void> = [];

  public static getInstance(): OrganizationManager {
    if (!OrganizationManager.instance) {
      OrganizationManager.instance = new OrganizationManager();
    }
    return OrganizationManager.instance;
  }

  public setOrganization(org: IOrganization) {
    this.organization = org;
    localStorage.setItem("orgId", org.id);
    localStorage.setItem("orgName", org.name);
    localStorage.setItem("orgDisplayName", org.displayName);

    setTimeout(() => this.listeners.forEach((listener) => listener(org.id)), 0);
  }

  public setRootOrganization(org: IOrganization) {
    localStorage.setItem("rootOrgId", org.id);
  }

  public clearRootOrganization() {
    localStorage.removeItem("rootOrgId");
  }

  public getRootOrgId(): string {
    return localStorage.getItem("rootOrgId") || "";
  }

  public getOrgId(): string {
    return this.organization?.id || localStorage.getItem("orgId") || "";
  }

  public getOrgName(): string | null {
    return this.organization?.name || localStorage.getItem("orgName") || null;
  }

  public getOrgDisplayName(): string {
    return this.organization?.displayName || "";
  }

  public getLoginUrl(): string {
    const orgName = this.getOrgName();
    return orgName ? `/login/${orgName}` : "/login";
  }

  public getOrganization(): IOrganization | null {
    return this.organization;
  }

  public async validateOrganization(orgName: string): Promise<boolean> {
    try {
      const data: any[] = [];

      const size = 10;
      let page = 0;
      let total = 0;
      let recordCount = 0;

      do {
        const response = await fetch(
          `${ConfigManager.getInstance().getApiUrl()}/api/v1/organization/public?order=ASC&limit=10&offset=${
            page * size
          }`,
        );
        const records = await response.json();
        if (Array.isArray(records.data)) {
          data.push(...records.data);
        } else {
          data.push(records.data);
        }
        total = records.total;
        recordCount += records.data.length;
        page++;
      } while (total > recordCount);

      const organizations: IOrganization[] = Array.isArray(data)
        ? data
        : [data];
      const organization = organizations.find(
        (org) => org.name === orgName && org.enabled && org.visible,
      );

      if (organization) {
        this.setOrganization(organization);
        return true;
      }

      return false;
    } catch (error) {
      console.error("Error validating organization:", error);
      return false;
    }
  }

  public onOrganizationChange(listener: (orgId: string) => void) {
    this.listeners.push(listener);
  }

  public removeOrganizationChangeListener(listener: (orgId: string) => void) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }
}
