interface IOrganization {
  id: string;
  name: string;
  displayName: string;
  logoId: string;
  enabled: boolean;
  visible: boolean;
}

export class ConfigManager {
  private static instance: ConfigManager;
  private apiUrl: string;
  private organization: IOrganization | null;
  private defaultOrgName: string = "default";

  private API_URL: string | undefined;
  private ADMIN_API_URL: string | undefined;
  private GROUP_ID: string | undefined;
  private STRIPE_KEY: string | undefined;
  private PARKING_NAME: string | undefined;

  private constructor() {
    this.apiUrl = "";
    this.organization = null;
  }

  public static getInstance(): ConfigManager {
    if (!ConfigManager.instance) {
      ConfigManager.instance = new ConfigManager();
    }
    return ConfigManager.instance;
  }

  public setApiUrl(url: string) {
    this.apiUrl = url;
  }

  public getApiUrl(): string {
    return this.apiUrl;
  }

  public getApiAdminUrl(): string | undefined {
    return this.ADMIN_API_URL;
  }

  public setApiAdminUrl(adminUrl: string) {
    this.ADMIN_API_URL = adminUrl;
  }

  public setStripeKey(key: string) {
    this.STRIPE_KEY = key;
  }

  public getStripeKey() {
    return this.STRIPE_KEY;
  }

  public getParkingName() {
    return this.PARKING_NAME;
  }

  public setParkingName(name: string) {
    this.PARKING_NAME = name;
  }
}
