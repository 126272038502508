import {
  Create,
  SimpleForm,
  required,
  useRedirect,
  TranslatableInputs,
  Toolbar,
  ListToolbarProps,
} from "react-admin";
import {
  StyledTextInput,
  StyledArraySelect,
  StyledSaveButton,
} from "../../../themes/styles";
import useWorkflowRoles from "../../../hooks/useWorkflowRoles";
import { workflowService } from "../../../api/helpdesk/WorkflowService";
import { IWorkflow } from "../../../types/interfaces/helpdesk/workflow/workflow.interface";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";

const WorkflowsCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const WorkflowsCreate = () => {
  const { isLoading, translatedWorkflowRoles } = useWorkflowRoles();
  const redirect = useRedirect();

  const getLastCreated = async () => {
    const { data } =
      await workflowService.getList<IBasicResponse<IWorkflow[]>>();

    return data[0].id;
  };

  const onSuccess = async () => {
    const lastCreatedId = await getLastCreated();

    if (!lastCreatedId) {
      redirect("list", "helpdesk/admin/group/workflows");
      return;
    }

    redirect("edit", "helpdesk/admin/group/workflows", lastCreatedId);
  };

  return (
    <Create mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<WorkflowsCreateFormToolbar />}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.auth.name"
            validate={required()}
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="description"
            label="app.auth.description"
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <StyledArraySelect
          source="roles"
          label="app.helpdesk.roles"
          choices={translatedWorkflowRoles}
          optionValue="role"
          isLoading={isLoading}
          validate={required()}
          variant="outlined"
          sx={{ width: "200px", marginTop: "15px" }}
        />
      </SimpleForm>
    </Create>
  );
};

export default WorkflowsCreate;
