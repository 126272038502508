import * as React from "react";
import { localStorageStore } from "ra-core";
import polyglotI18nProvider from "ra-i18n-polyglot";
import defaultMessages from "ra-language-english";
import { AdminContext, AdminContextProps, AdminUIProps } from "react-admin";
import { CustomAdminUI } from "./CustomAdminUI";

export const defaultI18nProvider = polyglotI18nProvider(
  () => defaultMessages,
  "en",
  [{ name: "en", value: "English" }],
  { allowMissing: true },
);
const defaultStore = localStorageStore();

export const CustomAdmin = (props: AdminProps) => {
  const {
    authProvider,
    basename,
    catchAll,
    children,
    dashboard,
    dataProvider,
    disableTelemetry,
    history,
    i18nProvider = defaultI18nProvider,
    layout,
    loading,
    loginPage,
    authCallbackPage,
    menu, // deprecated, use a custom layout instead
    notification,
    queryClient,
    requireAuth,
    store = defaultStore,
    ready,
    theme,
    lightTheme,
    darkTheme,
    defaultTheme,
    title = "React Admin",
  } = props;

  if (loginPage === true && process.env.NODE_ENV !== "production") {
    console.warn(
      "You passed true to the loginPage prop. You must either pass false to disable it or a component class to customize it",
    );
  }

  return (
    <AdminContext
      authProvider={authProvider}
      basename={basename}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      store={store}
      history={history}
      queryClient={queryClient}
      theme={theme}
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme={defaultTheme}
    >
      <CustomAdminUI
        layout={layout}
        dashboard={dashboard}
        disableTelemetry={disableTelemetry}
        menu={menu}
        catchAll={catchAll}
        title={title}
        loading={loading}
        loginPage={loginPage}
        authCallbackPage={authCallbackPage}
        notification={notification}
        requireAuth={requireAuth}
        ready={ready}
      >
        {children}
      </CustomAdminUI>
    </AdminContext>
  );
};

export default CustomAdmin;

export interface AdminProps extends AdminContextProps, AdminUIProps {}
