import * as React from "react";
import { createElement, ComponentType } from "react";
import { CoreAdminUIProps } from "ra-core";
import { ScopedCssBaseline } from "@mui/material";
import {
  NotFound,
  Layout as DefaultLayout,
  LoadingPage,
  Login,
  AuthCallback,
  Notification,
} from "react-admin";
import { CustomCoreAdminUI } from "./CustomCoreAdminUI";

export const CustomAdminUI = ({
  layout = DefaultLayout,
  catchAll = NotFound,
  loading = LoadingPage,
  loginPage = Login,
  authCallbackPage = AuthCallback,
  notification = Notification,
  ...props
}: AdminUIProps) => (
  <ScopedCssBaseline enableColorScheme>
    <CustomCoreAdminUI
      layout={layout}
      catchAll={catchAll}
      loading={loading}
      loginPage={loginPage}
      authCallbackPage={authCallbackPage}
      {...props}
    />
    {createElement(notification)}
  </ScopedCssBaseline>
);

export interface AdminUIProps extends CoreAdminUIProps {
  notification?: ComponentType;
}
