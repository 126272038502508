import { useCallback, useEffect, useState } from "react";
import {
  ArrayInput,
  Edit,
  ListToolbarProps,
  required,
  SimpleForm,
  SimpleFormIterator,
  TranslatableInputs,
  useEditContext,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Toolbar } from "@mui/material";
import { ReactFlowProvider } from "reactflow";
import {
  StyledArraySelect,
  StyledDeleteButton,
  StyledSaveButton,
  StyledSelect,
  StyledTextInput,
} from "../../../themes/styles";
import "reactflow/dist/style.css";
import Flow from "../../../components/resources/admin-parking/workflow/Flow";
import useWorkflowRoles from "../../../hooks/useWorkflowRoles";
import { IWorkflowStep } from "../../../types/interfaces/helpdesk/workflow/workflow-step.interface";
import { IUpdateWorkflowStatus } from "../../../types/interfaces/helpdesk/workflow/update-workflow-status.interface";
import { areNodesValid } from "../../../utils/workflow/workflowService";
import { TicketStatusType } from "../../../types/enums/ticket-status-type.enums";
import { httpClient } from "../../../providers/dataProvider";
import { ConfigManager } from "../../../constants/ConfigManager";
import ErrorMessageContainer from "../../../components/errors/ErrorContainer";
import { helpdeskWorkflowService } from "../../../api/HelpdeskWorkflow";
import { EActiveState } from "../../../types/enums/active-state.enum";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import { IWorkflow } from "../../../types/interfaces/helpdesk/workflow/workflow.interface";
import { helpdeskActiveTypes } from "../../../utils/helpdeskTicketStatusTypes";
import { ITicketAttribute } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { ITicketAttributeOption } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";

const WorkflowsEditFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton alwaysEnable />
      <StyledDeleteButton />
    </Toolbar>
  );
};

const WorkflowsEdit = () => {
  return (
    <Edit redirect="list" mutationMode="pessimistic">
      <EditPageView />
    </Edit>
  );
};

const EditPageView = () => {
  const context = useEditContext<IWorkflow>();
  const workflow = useRecordContext<IWorkflow>();
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();

  const { isLoading, translatedWorkflowRoles } = useWorkflowRoles();
  const [steps, setSteps] = useState<IWorkflowStep[]>([]);
  const [statuses, setStatuses] = useState<IUpdateWorkflowStatus[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isThereActiveWorkflow, setIsThereActiveWorkflow] =
    useState<boolean>(false);
  const attributes = useGetList<ITicketAttribute>(
    "helpdesk/admin/group/attributes",
    {
      sort: { field: "name", order: "ASC" },
    },
  );
  const [selectedAttribute, setSelectedAttribute] = useState<string | null>();
  const [selectedAttrOption, setSelectedAttrOption] = useState<string | null>(
    null,
  );

  const save = async (data: any): Promise<void> => {
    data.statuses = statuses;
    data.steps = steps;
    data.active = TicketStatusType.ACTIVE;
    data.condition = {
      combinator: "and",
      rules: [],
    };

    if (
      selectedAttribute &&
      selectedAttrOption &&
      data.condition.rules.length === 0
    ) {
      data.condition.rules.push({
        field: selectedAttribute,
        operator: "=",
        value: selectedAttrOption,
      });
    }

    setErrorMessage("");

    if (!areNodesValid(data.statuses)) {
      setErrorMessage(
        translate("app.helpdesk.workflow.notConnectedRequiredNodes"),
      );
    }

    try {
      await httpClient(
        `${ConfigManager.getInstance().getApiAdminUrl()}/helpdesk/admin/group/workflows/${
          data.id
        }`,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
      );

      redirect("list", "helpdesk/admin/group/workflows");
    } catch (e) {
      setErrorMessage(
        e?.translatedMessage || translate("app.notifications.error.undefined"),
      );
    }
  };

  const synchronizeWorkflowDataAndForm = (
    newStatuses: IUpdateWorkflowStatus[],
    newSteps: IWorkflowStep[],
  ): void => {
    newStatuses = newStatuses.map((status) => ({
      ...status,
      isFinal: status.isFinal || status.destinationSteps.length === 0,
    }));

    setStatuses(newStatuses);
    setSteps(newSteps);
  };

  const fetchActiveWorkflow = async (): Promise<IWorkflow[] | undefined> => {
    try {
      const { data } = await helpdeskWorkflowService.getWorkflowList<
        IBasicResponse<IWorkflow[]>
      >({ active: EActiveState.ACTIVE });

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const checkActiveWorkflows = async (): Promise<void> => {
    const workflows = await fetchActiveWorkflow();

    if (!workflows) {
      setIsThereActiveWorkflow(false);
      return;
    }

    const res = workflows.some((item: IWorkflow) => {
      const sameRoles = item.roles.some((role: string) =>
        workflow.roles.includes(role),
      );
      const sameCondition = item.condition.rules.some(
        (rule) =>
          rule.field === selectedAttribute && rule.value === selectedAttrOption,
      );

      return sameRoles && sameCondition;
    });

    setIsThereActiveWorkflow(res);
  };

  const changeConditionField = (value: string): void => {
    if (!value) {
      setSelectedAttribute(null);
      return;
    }

    const attribute = attributes.data?.find(
      (item: ITicketAttribute) => item.id === value,
    );
    if (!attribute) {
      setSelectedAttribute(null);
      return;
    }

    setSelectedAttribute(attribute.id);
  };

  const changeConditionValue = (value: string): void => {
    setSelectedAttrOption(value);
  };

  const getAttributes = useCallback(() => {
    if (attributes.isLoading || !attributes.data) {
      return [];
    }

    return attributes.data
      .filter(
        (item: ITicketAttribute) =>
          item.isRemovable && item.type === "single-select",
      )
      .map((item: ITicketAttribute) => ({
        id: item.id,
        name: item.name["pl"],
      }));
  }, [attributes]);

  const getAttributeOptions = useCallback(
    (id?: string | null) => {
      if (!id || attributes.isLoading || !attributes.data) {
        return [];
      }

      const attribute = attributes.data.find(
        (item: ITicketAttribute) => item.id === id,
      );

      if (!attribute) {
        return [];
      }

      return attribute.options.map((item: ITicketAttributeOption) => ({
        id: item.id,
        name: item.value["pl"],
      }));
    },
    [attributes.data, attributes.isLoading],
  );

  useEffect(() => {
    if (
      workflow &&
      Array.isArray(workflow?.condition?.rules) &&
      workflow.condition.rules.length > 0
    ) {
      const [rule] = workflow.condition.rules;
      setSelectedAttribute(rule.field);
      setSelectedAttrOption(rule.value);
    }
  }, [workflow, attributes.isLoading]);

  useEffect(() => {
    if (!context.record) {
      return;
    }

    if (
      Array.isArray(context.record?.condition?.rules) &&
      context.record.condition.rules.length === 0
    ) {
      context.record.condition.rules.push({
        field: "",
        operator: "",
        value: "",
      });
    } else if (!Array.isArray(context.record?.condition?.rules)) {
      context.record.condition = {
        combinator: "and",
        rules: [{ field: "", operator: "", value: "" }],
      };
    }
  }, [context?.record]);

  useEffect(() => {
    checkActiveWorkflows();
  }, []);

  const handleActiveChange = (event: any): void => {
    if (isThereActiveWorkflow && event.target.value === EActiveState.ACTIVE) {
      notify(translate("app.helpdesk.workflow.activeMessage"), {
        type: "warning",
      });
    }
  };

  return (
    <>
      <SimpleForm toolbar={<WorkflowsEditFormToolbar />} onSubmit={save}>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="name"
            label="app.auth.name"
            validate={required()}
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="description"
            label="app.auth.description"
            fullWidth
            style={{ marginTop: "15px" }}
          />
        </TranslatableInputs>
        <StyledArraySelect
          source="roles"
          label="app.auth.role"
          choices={translatedWorkflowRoles}
          optionValue="role"
          isLoading={isLoading}
          validate={required()}
          variant="outlined"
          sx={{ width: "200px", marginTop: "15px" }}
        />
        <StyledSelect
          source="active"
          label="app.helpdesk.workflow.active"
          choices={helpdeskActiveTypes}
          isLoading={isLoading}
          validate={required()}
          variant="outlined"
          onChange={handleActiveChange}
          sx={{ width: "200px", marginTop: "15px" }}
        />
        <ArrayInput
          source="condition.rules"
          label="app.helpdesk.workflow.condition"
        >
          <SimpleFormIterator
            inline
            disableAdd
            disableRemove
            disableClear
            disableReordering
          >
            <StyledSelect
              source="field"
              label="app.helpdesk.workflow.conditionField"
              choices={getAttributes()}
              isLoading={isLoading || attributes.isLoading}
              variant="outlined"
              sx={{ width: "200px", marginTop: "15px" }}
              onChange={(e) => changeConditionField(e.target.value)}
            />
            <StyledSelect
              source="value"
              label="app.helpdesk.workflow.conditionValue"
              choices={getAttributeOptions(selectedAttribute)}
              value={selectedAttrOption}
              isLoading={isLoading || attributes.isLoading}
              variant="outlined"
              sx={{ width: "200px", marginTop: "15px" }}
              required={!!selectedAttribute}
              onChange={(e) => changeConditionValue(e.target.value)}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>

      {errorMessage && (
        <div style={{ textAlign: "center" }}>
          <ErrorMessageContainer>
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          </ErrorMessageContainer>
        </div>
      )}

      <div className="flow-container" style={{ height: "500px" }}>
        <ReactFlowProvider>
          <Flow
            synchronizeWorkflowDataAndForm={synchronizeWorkflowDataAndForm}
          />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default WorkflowsEdit;
