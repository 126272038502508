import React from "react";
import { Resource } from "react-admin";
import UserIcon from "../../components/icons/UserIcon";
import usersResource from "./users";

const auth = () => {
  const resources = [];

  const usersUrl = `admin/group/users`;

  resources.push(
    <Resource
      {...usersResource}
      name={usersUrl}
      key={usersUrl}
      options={{
        label: "app.auth.users",
        menuParent: "users-parent",
        isMenuParent: false,
        sectionKey: "user-section",
        sectionName: "app.system.system_application",
      }}
    />,
  );

  resources.push(
    <Resource
      key="users-parent"
      name="users-parent"
      icon={UserIcon}
      options={{
        label: "app.auth.parent",
        isMenuParent: true,
        sectionKey: "user-section",
        sectionName: "app.system.system_application",
      }}
    />,
  );

  return resources;
};

export default auth;
