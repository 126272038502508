import React from "react";
import {
  BooleanInput,
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  useRecordContext,
  useTranslate,
  useAuthenticated,
  useNotify,
  useRedirect,
  usePermissions,
} from "react-admin";
import { Box, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  buttons,
  StyledArraySelect,
  StyledTextInput,
} from "../../../themes/styles";
import { userService } from "../../../api/UserService";
import { Roles } from "../../../types/enums/roles";
import GroupSelectInput from "../../../components/common/GroupSelectInput";
const UserEditFormToolbar = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton type="submit" />
      <DeleteButton
        confirmContent={
          <div>
            {translate("app.auth.delete_confirm", {
              name: record?.firstName + " " + record?.lastName,
            })}
            ?
          </div>
        }
        confirmTitle={<div>{translate("app.auth.confirmation")}</div>}
      />
    </Toolbar>
  );
};

const UsersEdit = () => {
  useAuthenticated();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { id: userId } = useParams();

  const { permissions } = usePermissions<string[]>();

  const handleSubmit = async (values: any) => {
    try {
      await userService.updateUser(userId, values);
      notify(translate("app.auth.user_updated"), { type: "success" });
      redirect("list", "admin/group/users");
    } catch (error) {
      if (error.subErrors) {
        const errorMessages = error.subErrors
          .map((subError: any) => subError.message)
          .join("\n");
        notify(errorMessages, { type: "error", multiLine: true });
      } else if (error.translatedMessage) {
        notify(error.translatedMessage, { type: "error" });
      } else {
        notify("ra.notification.http_error", { type: "error" });
      }
    }
  };

  return (
    <Edit
      redirect="show"
      mutationMode="pessimistic"
      sx={{
        "& .MuiButton-text, .MuiButton-root": buttons.whiteButton,
        "& .RaEdit-main": {
          paddingTop: "40px",
        },
      }}
    >
      <SimpleForm
        sx={{ paddingTop: "20px" }}
        toolbar={<UserEditFormToolbar />}
        onSubmit={handleSubmit}
      >
        <StyledTextInput
          name="firstName"
          variant="outlined"
          source="firstName"
          label="app.auth.name"
          validate={required()}
        />

        <StyledTextInput
          name="email"
          variant="outlined"
          source="email"
          label="app.auth.email"
          validate={required()}
        />

        <StyledTextInput
          name="lastName"
          variant="outlined"
          source="lastName"
          label="app.auth.surname"
          validate={required()}
        />

        <StyledTextInput
          name="phoneNumber"
          variant="outlined"
          source="phoneNumber"
          label="app.auth.phoneNumber"
          validate={required()}
        />

        <BooleanInput
          name="active"
          source="active"
          variant="outlined"
          label="app.auth.active"
        />

        <StyledArraySelect
          variant="outlined"
          name="roles"
          choices={[
            { id: "ADMIN", name: translate("app.auth.roles_list.admin") },
            {
              id: "DELIVERY_ADMIN",
              name: translate("app.auth.roles_list.delivery_admin"),
            },
            {
              id: "HELPDESK_ADMIN",
              name: translate("app.auth.roles_list.helpdesk_admin"),
            },
            {
              id: "PARKING_ADMIN",
              name: translate("app.auth.roles_list.parking_admin"),
            },
            {
              id: "PARKING_MANAGER",
              name: translate("app.auth.roles_list.parking_manager"),
            },
            { id: "EMPLOYEE", name: translate("app.auth.roles_list.employee") },
            {
              id: "HELPDESK_ASSIGNEE",
              name: translate("app.auth.roles_list.helpdesk_assignee"),
            },
            { id: "CUSTOMER", name: translate("app.auth.roles_list.customer") },
          ]}
          label="app.auth.roles"
          source="roles"
        />
        {permissions &&
          permissions.some((role) =>
            role.startsWith(Roles.ROLE_GROUP_ADMIN),
          ) && (
            <Box sx={{ marginTop: "20px" }}>
              <GroupSelectInput
                source="groupId"
                label="app.administration.tenants.tenant"
                isRequired
              />
            </Box>
          )}
      </SimpleForm>
    </Edit>
  );
};

export default UsersEdit;
