import RampsList from "./RampsList";
import RampsCreate from "./RampsCreate";
import RampsEdit from "./RampsEdit";

const rampsResource = {
  list: RampsList,
  create: RampsCreate,
  edit: RampsEdit,
};

export default rampsResource;
