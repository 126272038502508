import { ITicketAttribute } from "../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { ITicketAttributeOption } from "../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import { EAllowedAttributeType } from "../../types/enums/ticket-attribute-type.enums";
import { IRamp } from "../../types/interfaces/delivery-zone/ramp.interface";
import { IRampsGridRow } from "../../resources/delivery-zone/ramps/RampsList";

export const reformatToAcceptableByListComponent = (data: IRamp[]) => {
  const attributes = data.map((rampItem: IRamp) => {
    return rampItem.attributes.map((attrItem: ITicketAttribute) => {
      let value: string | ITicketAttributeOption | undefined = attrItem.value;
      if (
        attrItem.type === EAllowedAttributeType.Status ||
        attrItem.type === EAllowedAttributeType.SingleSelect
      ) {
        value = attrItem.options?.find(
          (optionItem) => optionItem.id === attrItem.value,
        );
      }

      return {
        createdAt: rampItem.createdAt,
        updatedAt: rampItem.updatedAt,
        createdBy: rampItem.createdBy,
        active: rampItem.active,
        name: attrItem.name.en.toLowerCase(),
        value,
        id: rampItem.id,
      };
    });
  });

  // here we are processing array of attributes for each object
  return attributes.map((attributeArrayItem: any) => {
    const rampRowInProperFormat: IRampsGridRow = {} as IRampsGridRow;
    attributeArrayItem.map((attributeItem: IRampsGridRow) => {
      rampRowInProperFormat.id = attributeItem.id;
      rampRowInProperFormat.createdBy = attributeItem.createdBy;
      rampRowInProperFormat.createdAt = attributeItem.createdAt;
      rampRowInProperFormat.active = attributeItem.active;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rampRowInProperFormat[
        attributeItem.name as keyof typeof rampRowInProperFormat
      ] = attributeItem.value;
    });

    return rampRowInProperFormat;
  });
};
