import React from "react";
import {
  Edit,
  required,
  SimpleForm,
  Toolbar,
  TranslatableInputs,
  useEditContext,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import { FileRejection } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router";
import {
  StyledNumberInput,
  StyledSaveButton,
  StyledTextInput,
  StyledImageInput,
  StyledSelect,
} from "../../../themes/styles";
import CustomImageField from "../../../components/forms/CustomImageField";
import { mobileModulesPageService } from "../../../api/MobileModulesPageService";
import { mobileActivityTypes } from "../mobile-modules/mobileActivityTypes";
import useUploadModuleIcon from "../../../hooks/useUploadModuleIcon";

const ModuleOnboardingPagesEditToolbar = () => (
  <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
    <StyledSaveButton />
  </Toolbar>
);

const ModuleOnboardingPagesEditForm = () => {
  const { state } = useLocation();

  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const refresh = useRefresh();

  const {
    uploadedAttachmentId,
    deleteUploadedFile,
    uploadFiles,
    handleFileRejections,
  } = useUploadModuleIcon();

  const { record } = useEditContext();

  const onSubmit = async (data: any) => {
    delete data.icon;
    delete data.id;
    data.iconId = uploadedAttachmentId;

    try {
      await mobileModulesPageService.updateMobileModulePage(
        data,
        state.moduleId,
        record.id,
      );
      notify(translate("app.mobile.module_updated_success"), {
        type: "success",
      });
      refresh();
      redirect("edit", "mobile/admin/group/modules", state.moduleId);
    } catch (e) {
      notify(
        e?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  return (
    <SimpleForm
      toolbar={<ModuleOnboardingPagesEditToolbar />}
      onSubmit={onSubmit}
    >
      <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
        <StyledTextInput
          variant="outlined"
          source="title"
          label="app.auth.title"
          validate={required()}
        />
        <StyledTextInput
          variant="outlined"
          label="app.auth.content"
          source={"content"}
          rows={8}
          multiline
          fullWidth
          validate={required()}
        />
      </TranslatableInputs>
      <StyledNumberInput
        variant="outlined"
        source="position"
        label="app.mobile.position"
        min={0}
        validate={required()}
        style={{ marginTop: 20 }}
      />
      <StyledSelect
        source="active"
        label="app.mobile.active"
        choices={mobileActivityTypes}
        validate={required()}
        variant="outlined"
        sx={{ width: "200px", marginTop: "15px" }}
      />
      <StyledImageInput
        variant="outlined"
        source="icon"
        label="app.mobile.icon"
        accept={["image/jpeg", "image/png", "image/gif", "image/bmp"]}
        maxSize={500000}
        removeIcon={DeleteIcon}
        validate={required()}
        onRemove={() => deleteUploadedFile()}
        options={{
          onDrop: (files: File[]) => uploadFiles(files),
          onDropRejected: (fileRejections: FileRejection[]) =>
            handleFileRejections(fileRejections),
        }}
      >
        <CustomImageField />
      </StyledImageInput>
    </SimpleForm>
  );
};

const ModuleOnboardingPagesEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <ModuleOnboardingPagesEditForm />
    </Edit>
  );
};

export default ModuleOnboardingPagesEdit;
