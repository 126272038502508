import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../constants/ConfigManager";
import { OrganizationManager } from "../constants/OrganizationManager";
import { EResource, getResourceUrl } from "../utils/resourcesHelper";
import { apiRequest } from "./client";

const updateTicketAttribute = async <T>(
  attributeId: string | undefined,
  ticketId: string,
  data: { value: string },
) => {
  const attrUrl = getResourceUrl(EResource.UPDATE_TICKET_ATTRIBUTES, {
    attributeId,
    ticketId,
  });

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${attrUrl}`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "updateTicketAttribute");
    throw e;
  }
};

const getAttributes = async <T>() => {
  const attrUrl = getResourceUrl(EResource.ATTRIBUTES);

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${attrUrl}`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const getAttribute = async <T>(attributeId: string) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/attributes/${attributeId}`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

const createAttribute = async <T>(data: any) => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/attributes`,
      data,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    throw e.response?.data?.error;
  }
};

export const helpdeskAttributesService = {
  updateTicketAttribute,
  getAttributes,
  getAttribute,
  createAttribute,
};
