import { useEffect, useState } from "react";
import { IBasicResponse } from "../types/interfaces/response/basic-response.interface";
import { IConfigResponse } from "../types/interfaces/config-response.interface";
import { fetchConfig } from "../utils/config";
import { SettingsManager } from "../constants/SettingsManager";
import { deliveryService } from "../api/deliveryZone/DeliveryService";

const defaultSettings: TDeliverySettings = {
  enabled: false,
};

export type TDeliverySettings = {
  enabled: boolean;
};

const useHelpdeskSettings = (orgId?: string) => {
  const [settings, setSettings] = useState<TDeliverySettings>(defaultSettings);

  useEffect(() => {
    if (localStorage.getItem("orgId")) {
      assignSettings();
    }
  }, [orgId]);

  const fetchSettings = async () => {
    try {
      const { api_url }: IConfigResponse = await fetchConfig();
      const { data } =
        await deliveryService.getSettings<IBasicResponse<TDeliverySettings>>(
          api_url,
        );

      return data;
    } catch (e) {
      return defaultSettings;
    }
  };

  const assignSettings = async () => {
    const settings = await fetchSettings();

    SettingsManager.getInstance().setDeliverySettings(settings);
    setSettings(settings);
  };

  return { settings };
};

export default useHelpdeskSettings;
