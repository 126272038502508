import React from "react";
import {
  Create,
  required,
  SimpleForm,
  Toolbar,
  TranslatableInputs,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import { useParams } from "react-router-dom";
import { FileRejection } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomImageField from "../../../components/forms/CustomImageField";
import {
  StyledNumberInput,
  StyledSaveButton,
  StyledTextInput,
  StyledImageInput,
} from "../../../themes/styles";
import { mobileModulesPageService } from "../../../api/MobileModulesPageService";
import useUploadModuleIcon from "../../../hooks/useUploadModuleIcon";

const ModuleOnboardingPagesCreateToolbar = () => (
  <Toolbar>
    <StyledSaveButton />
  </Toolbar>
);

const ModuleOnboardingPagesCreate = () => {
  const { moduleId } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const refresh = useRefresh();

  const {
    uploadedAttachmentId,
    deleteUploadedFile,
    uploadFiles,
    handleFileRejections,
  } = useUploadModuleIcon();

  const onSubmit = async (data: any) => {
    const newPageData = {
      ...data,
      iconId: uploadedAttachmentId,
    };

    try {
      await mobileModulesPageService.createMobileModulePage(
        newPageData,
        moduleId,
      );
      notify(translate("app.mobile.module_updated_success"), {
        type: "success",
      });
      refresh();
      redirect("edit", "mobile/admin/group/modules");
    } catch (e) {
      notify(
        e?.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        {
          type: "error",
        },
      );
    }
  };

  return (
    <Create>
      <SimpleForm
        onSubmit={onSubmit}
        toolbar={<ModuleOnboardingPagesCreateToolbar />}
      >
        <TranslatableInputs locales={["en", "pl"]} defaultLocale="en">
          <StyledTextInput
            variant="outlined"
            source="title"
            label="app.auth.title"
            validate={required()}
            style={{ marginTop: 20 }}
          />
          <StyledTextInput
            variant="outlined"
            label="app.auth.content"
            source={"content"}
            rows={8}
            multiline
            fullWidth
            validate={required()}
          />
        </TranslatableInputs>
        <StyledNumberInput
          variant="outlined"
          source="order"
          label="app.mobile.position"
          min={0}
          validate={required()}
          style={{ marginTop: 20 }}
        />
        <StyledImageInput
          variant="outlined"
          source="iconId"
          label="app.mobile.icon"
          accept={["image/jpeg", "image/png", "image/gif", "image/bmp"]}
          maxSize={500000}
          removeIcon={DeleteIcon}
          validate={required()}
          onRemove={() => deleteUploadedFile()}
          options={{
            onDrop: (files: File[]) => uploadFiles(files),
            onDropRejected: (fileRejections: FileRejection[]) =>
              handleFileRejections(fileRejections),
          }}
        >
          <CustomImageField />
        </StyledImageInput>
      </SimpleForm>
    </Create>
  );
};

export default ModuleOnboardingPagesCreate;
