import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { useTranslate } from "react-admin";
import { AuthPageContainer } from "../themes/styles";
import LogoIcon from "./icons/LogoIcon";

const OrganizationLandingPage = () => {
  const translate = useTranslate();

  return (
    <AuthPageContainer>
      <LogoIcon width={110} height={60} />
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={3}
          mt={4}
        >
          <Typography variant="h5" align="center">
            {translate("app.auth.organization_required_title")}
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center">
            {translate("app.auth.organization_required_description")}
          </Typography>
        </Box>
      </Container>
    </AuthPageContainer>
  );
};

export default OrganizationLandingPage;
