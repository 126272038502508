import { AxiosRequestConfig } from "axios";
import { ConfigManager } from "../../constants/ConfigManager";
import { apiRequest } from "../client";
import { OrganizationManager } from "../../constants/OrganizationManager";

const getList = async <T>() => {
  const orgId = OrganizationManager.getInstance().getOrgId();

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/helpdesk/admin/${orgId}/workflows`,
    };
    return await apiRequest<T>(config);
  } catch (e) {
    console.log(e, "getList");
    throw e;
  }
};

export const workflowService = {
  getList,
};
