import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, FormControlLabel, Grid } from "@mui/material";
import {
  Confirm,
  Edit,
  resolveBrowserLocale,
  useAuthenticated,
  useLocaleState,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { styled } from "@mui/material/styles";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import rrulePlugin from "@fullcalendar/rrule";
import moment from "moment-timezone";
import { RRule, Weekday } from "rrule";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { buttons, itemInfoComponent } from "../../../themes/styles";
import { IRamp } from "../../../types/interfaces/delivery-zone/ramp.interface";
import AttributeField from "../../../components/resources/helpdesk/tickets/AttributeField";
import RampHistory from "../../../components/resources/helpdesk/show/RampHistory";
import { rampService } from "../../../api/deliveryZone/RampService";
import { EActiveState } from "../../../types/enums/active-state.enum";
import { EResource, getResourceUrl } from "../../../utils/resourcesHelper";
import { millisecondsToDuration } from "../../../utils/delivery/timeslot";
import EventDialog from "./components/EventDialog";
import "./FullCalendar.css";

const RampDetailSection = styled(Box)(() => ({
  margin: "0 0 0 10px",
  padding: "10px",
  textAlign: "center",
}));

const weekDaysMap = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const timeZone = "Europe/Warsaw";

const TicketEditComponent = () => {
  const ramp = useRecordContext<IRamp>();

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [locale] = useLocaleState();

  const [events, setEvents] = useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedStart, setSelectedStart] = useState<Date | null>(null);
  const [selectedEnd, setSelectedEnd] = useState<Date | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const calendarRef = useRef<FullCalendar>(null);

  const rampsList = getResourceUrl(EResource.RAMPS_LIST);

  useEffect(() => {
    const initialEvents = ramp?.timeslots.map((timeslot) => {
      const event: any = {
        id: timeslot.id,
        allDay: false,
        extendedProps: {
          createdAt: timeslot.createdAt,
          timeslotId: timeslot.id,
          active: timeslot.active,
        },
      };

      const startTime = timeslot.startTime;
      const endTime = timeslot.endTime;

      if (timeslot.weekDays && timeslot.weekDays.length > 0) {
        const dayNameToRRuleWeekday = {
          monday: RRule.MO,
          tuesday: RRule.TU,
          wednesday: RRule.WE,
          thursday: RRule.TH,
          friday: RRule.FR,
          saturday: RRule.SA,
          sunday: RRule.SU,
        };

        const byweekday = timeslot.weekDays.map(
          (day) =>
            dayNameToRRuleWeekday[
              day.toLowerCase() as keyof typeof dayNameToRRuleWeekday
            ],
        );

        const dtstart = moment(`${timeslot.startDate} ${startTime}`).format(
          "YYYY-MM-DD HH:mm",
        );
        const until = moment(`${timeslot.endDate} ${endTime}`).format(
          "YYYY-MM-DD HH:mm",
        );

        event.rrule = {
          freq: RRule.WEEKLY,
          dtstart: dtstart,
          until: until,
          byweekday: byweekday,
        };

        const start = moment.tz(startTime, "HH:mm", timeZone);
        const end = moment.tz(endTime, "HH:mm", timeZone);
        const durationMs = end.diff(start);
        const duration = moment.duration(durationMs);
        event.duration = {
          hours: duration.hours(),
          minutes: duration.minutes(),
        };
      } else {
        const startDateTime = moment(
          `${timeslot.startDate} ${startTime}`,
        ).format("YYYY-MM-DD HH:mm");
        const endDateTime = moment(`${timeslot.endDate} ${endTime}`).format(
          "YYYY-MM-DD HH:mm",
        );

        event.start = startDateTime;
        event.end = endDateTime;
      }

      return event;
    });

    setEvents(initialEvents);
  }, [ramp]);

  const handleSelect = (selectInfo: any) => {
    setSelectedStart(selectInfo.start);
    setSelectedEnd(selectInfo.end);
    setSelectedEvent(null);
    setDialogOpen(true);
  };

  const updateTimeslots = (eventsList: any) => {
    ramp.timeslots = eventsList.map((event: any) => {
      const timeslot: any = {};

      const now = Date.now();

      if (event.extendedProps && event.extendedProps.timeslotId) {
        timeslot.id = event.extendedProps.timeslotId;
        timeslot.createdAt = event.extendedProps.createdAt;
        timeslot.updatedAt = now;
      }

      timeslot.active = event?.extendedProps.active || "active";

      if (event.rrule) {
        // Recurring event
        const rruleObject = event.rrule;
        const dtstart = moment(rruleObject.dtstart).tz(timeZone);
        const until = rruleObject.until
          ? moment(rruleObject.until).tz(timeZone)
          : null;

        timeslot.startDate = dtstart.format("YYYY-MM-DD");
        timeslot.startTime = dtstart.format("HH:mm");

        timeslot.endDate = until ? until.format("YYYY-MM-DD") : null;

        const duration = moment.duration(event.duration);
        timeslot.endTime = dtstart.clone().add(duration).format("HH:mm");

        timeslot.weekDays = rruleObject.byweekday.map(
          (weekday: Weekday) => weekDaysMap[weekday.getJsWeekday()],
        );
      } else {
        // Non-recurring event
        const startDateTime = moment(
          event.start instanceof Date
            ? event.start.toISOString()
            : event.start!,
          moment.ISO_8601,
        ).tz(timeZone);

        const endDateTime = moment(
          event.end instanceof Date ? event.end.toISOString() : event.end!,
          moment.ISO_8601,
        ).tz(timeZone);

        timeslot.startDate = startDateTime.format("YYYY-MM-DD");
        timeslot.startTime = startDateTime.format("HH:mm");

        timeslot.endDate = endDateTime.format("YYYY-MM-DD");
        timeslot.endTime = endDateTime.format("HH:mm");

        timeslot.weekDays = null;
      }

      return timeslot;
    });
  };

  const handleEventClick = (clickInfo: any) => {
    if (clickInfo.event.end < new Date()) {
      return;
    }

    setSelectedEvent(clickInfo.event);
    setDialogOpen(true);
  };

  const markTimeSlotAsRemoved = (eventId: string) => {
    const markedAsRemoved = events.map((event: any) => {
      return event.id === eventId
        ? {
            ...event,
            extendedProps: { ...event.extendedProps, active: "removed" },
          }
        : event;
    });
    updateTimeslots(markedAsRemoved);
  };

  const handleEventRemove = (eventRemoveInfo: any) => {
    const updatedEvents = events.filter((event: any) => {
      return event.id !== eventRemoveInfo;
    });
    setEvents(updatedEvents);
    updateTimeslots(updatedEvents);
    setDialogOpen(false);
  };

  const handleDialogSave = (eventData: any, eventId: string) => {
    if (eventId) {
      const updatedEvents = events.map((evt: any) => {
        if (evt.id === eventId) {
          return {
            ...evt,
            ...eventData,
            id: eventId,
            extendedProps: {
              ...evt.extendedProps,
              active: eventData.active,
            },
          };
        }
        return evt;
      });
      setEvents(updatedEvents);
      updateTimeslots(updatedEvents);
    } else {
      const newEvent = {
        id: String(
          events.length > 0 ? parseInt(events[events.length - 1].id) + 1 : 0,
        ),
        start: eventData.start,
        end: eventData.end,
        rrule: eventData.rrule,
        allDay: false,
        duration: 0,
        extendedProps: {
          active: eventData.active,
        },
      };

      if (eventData.rrule) {
        const dtstart = eventData.start.toISOString();
        const until = eventData.rrule.until
          ? eventData.rrule.until.toISOString()
          : undefined;

        newEvent.rrule = {
          ...eventData.rrule,
          dtstart,
          until,
        };
      } else {
        newEvent.start = eventData.start;
        newEvent.end = eventData.end;
      }

      const durationMs = eventData.end.getTime() - eventData.start.getTime();
      newEvent.duration = millisecondsToDuration(durationMs);

      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi) {
        calendarApi.addEvent(newEvent);
      }

      setEvents((prevEvents: any) => [...prevEvents, newEvent]);
      updateTimeslots([...events, newEvent]);
    }

    setSelectedEvent(null);
    setDialogOpen(false);
  };

  const handleSaveRamp = async () => {
    const data = {
      active: ramp.active,
      timeslots: ramp.timeslots.map((timeslot) => {
        delete timeslot.createdAt;
        delete timeslot.updatedAt;
        return timeslot;
      }),
    };

    try {
      await rampService.updateRamp(data, ramp.id);
      notify(translate("app.delivery_zone.ramps.ramp_created"), {
        type: "success",
      });
      redirect("list", rampsList);
    } catch (e) {
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        { type: "error" },
      );
    }
  };

  const handleDelete = async () => {
    try {
      await rampService.deleteRamp(ramp.id);
      notify(translate("app.delivery_zone.ramps.ramp_deleted"), {
        type: "success",
      });
      redirect("list", rampsList);
    } catch (e) {
      notify(
        e.translatedMessage ||
          translate("app.notifications.error.unexpected_error"),
        { type: "error" },
      );
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setSelectedEvent(null);
  };

  const resolveClassNames = (arg: any) => {
    const classNames: string[] = [];

    if (arg.event.extendedProps.active === "inactive") {
      classNames.push("fc-event-inactive");
    }

    if (arg.event.end < new Date()) {
      classNames.push("fc-event-expired");
    }

    return classNames;
  };

  return (
    <Box>
      <RampDetailSection>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <b>{translate("app.delivery_zone.ramps.ramps_detail")}</b>
          <Box style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="outlined"
              sx={buttons.darkButton}
              onClick={handleSaveRamp}
            >
              {translate("app.delivery_zone.ramps.save")}
            </Button>
            <Button
              variant="outlined"
              sx={buttons.darkButton}
              onClick={() => setDeleteDialogOpen(true)}
            >
              {translate("app.delivery_zone.ramps.delete")}
            </Button>
            <Confirm
              isOpen={deleteDialogOpen}
              title=""
              content={translate(
                "app.delivery_zone.ramps.delete_dialog_content",
              )}
              onConfirm={handleDelete}
              onClose={() => setDeleteDialogOpen(false)}
            />
          </Box>
        </Box>
      </RampDetailSection>
      <Box sx={itemInfoComponent}>
        <Grid container spacing={3} sx={{ ml: 0, mt: 0, mb: 2, p: 3 }}>
          <Grid className="item-info__container" item xs={6}>
            {ramp?.attributes.map((item) => {
              return (
                <Grid container key={item.id}>
                  <Grid item xs={6}>
                    <span className="item-info__title">
                      {item.name[locale as keyof typeof item.name]}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <AttributeField record={item} />
                  </Grid>
                </Grid>
              );
            })}

            {ramp?.createdBy && (
              <Grid container>
                <Grid item xs={6}>
                  <span className="item-info__title">
                    {translate("app.helpdesk.created_by")}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  {`${ramp.createdBy.firstName} ${ramp.createdBy.lastName[0]}.`}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <FormControl style={{ margin: "0 20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={ramp.active === EActiveState.ACTIVE}
                onChange={(_, checked) =>
                  (ramp.active = checked ? "active" : "inactive")
                }
              />
            }
            label={translate("app.delivery_zone.ramps.is_ramp_active")}
          />
        </FormControl>
      </Box>
      <RampDetailSection>
        <FullCalendar
          ref={calendarRef}
          plugins={[
            timeGridPlugin,
            interactionPlugin,
            rrulePlugin,
            momentTimezonePlugin,
          ]}
          initialView="timeGridWeek"
          selectable={true}
          selectMirror={true}
          selectLongPressDelay={500}
          selectConstraint={{ start: new Date() }}
          weekends={true}
          events={events}
          eventClassNames={resolveClassNames}
          select={handleSelect}
          eventRemove={handleEventRemove}
          eventClick={handleEventClick}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "timeGridDay,timeGridWeek",
          }}
          locale={resolveBrowserLocale()}
          height="auto"
          timeZone={timeZone}
          firstDay={1}
        />
      </RampDetailSection>
      <RampDetailSection>
        <RampHistory id={ramp?.id} />
      </RampDetailSection>
      <EventDialog
        open={dialogOpen}
        onClose={closeDialog}
        event={selectedEvent}
        markTimeSlotAsRemoved={markTimeSlotAsRemoved}
        onDelete={handleEventRemove}
        onSave={handleDialogSave}
        initialStart={selectedStart || new Date()}
        initialEnd={selectedEnd || new Date()}
      />
    </Box>
  );
};

const RampsEdit = () => {
  useAuthenticated();

  return (
    <Edit actions={false}>
      <TicketEditComponent />
    </Edit>
  );
};

export default RampsEdit;
