export const millisecondsToDuration = (milliseconds: number) => {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const remainderAfterHours = milliseconds % (1000 * 60 * 60);
  const minutes = Math.floor(remainderAfterHours / (1000 * 60));
  const remainderAfterMinutes = remainderAfterHours % (1000 * 60);
  const seconds = Math.floor(remainderAfterMinutes / 1000);

  const duration: any = {};
  if (hours > 0) duration.hours = hours;
  if (minutes > 0) duration.minutes = minutes;
  if (seconds > 0) duration.seconds = seconds;

  return duration;
};
