import React from "react";
import {
  BooleanInput,
  Create,
  ListToolbarProps,
  SimpleForm,
  useAuthenticated,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Toolbar } from "@mui/material";
import { StyledSaveButton, StyledTextInput } from "../../../themes/styles";
import { tenantsService } from "../../../api/administration/TenantsService";

const TenantsCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const TenantsCreate = () => {
  useAuthenticated();

  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleSubmit = async (values: any) => {
    try {
      const data = {
        ...values,
        name: values.displayName
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^a-z0-9_]/g, ""),
      };
      await tenantsService.createTenant(data);
      notify(translate("app.administration.tenants.successfully_created"), {
        type: "success",
      });
      redirect("list", "organization/admin/group/tenants");
    } catch (error) {
      if (error.subErrors.length > 0) {
        const errorMessages = error.subErrors
          .map((subError: any) => subError.message)
          .join("\n");
        notify(errorMessages, { type: "error", multiLine: true });
      } else if (error.translatedMessage) {
        notify(error.translatedMessage, { type: "error" });
      } else {
        notify("ra.notification.http_error", { type: "error" });
      }
    }
  };

  return (
    <Create redirect="list">
      <SimpleForm
        toolbar={<TenantsCreateFormToolbar />}
        onSubmit={handleSubmit}
      >
        <StyledTextInput
          source="displayName"
          variant="outlined"
          label="app.administration.tenants.tenant_name"
          fullWidth
          validate={(value) => {
            if (!value) return "app.validation.required";
            if (value.length > 50) return "app.validation.max_length_50";
            return undefined;
          }}
        />
        <BooleanInput
          source="enabled"
          label="app.administration.tenants.enabled"
          defaultValue={true}
        />
        <BooleanInput
          source="visible"
          label="app.administration.tenants.visible"
          defaultValue={true}
        />
      </SimpleForm>
    </Create>
  );
};

export default TenantsCreate;
