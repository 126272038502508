import React, { cloneElement } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  FunctionField,
  resolveBrowserLocale,
  TextField,
  TopToolbar,
  useAuthenticated,
} from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../../components/common/CustomPagination";
import {
  listTopFilter,
  StyledList,
  StyledTextInput,
} from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import { IReservation } from "../../../types/interfaces/admin-parking/reservation.interface";
import CustomInputWrapper from "../../../components/common/CustomInputWrapper";

const ReservationsListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const ReservationsListActions = (props: any) => {
  const { filters } = props;

  return (
    <TopToolbar sx={listTopFilter.topToolbar.rightFilters}>
      {cloneElement(filters, { context: "button" })}
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const ReservationsListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <CustomInputWrapper
      source="plateNumber"
      alwaysOn
      resettable
      startIcon={<SearchIcon color="disabled" fontSize="large" />}
      placeholder="app.auth.search"
    />
    <DateInput source="from" label="app.parking.from_date" variant="outlined" />
    <DateInput source="to" label="app.parking.to_date" variant="outlined" />

    <StyledTextInput
      source="firstName"
      variant="outlined"
      label="app.parking.user_first_name"
    />

    <StyledTextInput
      source="phoneNumber"
      variant="outlined"
      label="app.parking.user_phone_number"
    />

    <StyledTextInput
      source="lastName"
      variant="outlined"
      label="app.parking.user_last_name"
    />

    <StyledTextInput
      source="email"
      variant="outlined"
      label="app.parking.user_email"
    />
  </Filter>
);

const ReservationsList = () => {
  useAuthenticated();

  return (
    <StyledList
      className="styled-list"
      actions={<ReservationsListActions />}
      pagination={<ReservationsListPagination />}
      filters={<ReservationsListFilter />}
      exporter={false}
      disableSyncWithLocation={true}
    >
      <ReservationsListView />
    </StyledList>
  );
};

const ReservationsListView = () => {
  return (
    <SkeletonLoaderList>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField
          source="vehicle.plateNumber"
          label="app.parking.plate_number"
          sortable={false}
        />
        <DateField
          source="from"
          label="app.parking.reservationFrom"
          showTime={true}
          locales={resolveBrowserLocale()}
          options={{ timeZone: "utc" }}
        />
        <DateField
          source="to"
          label="app.parking.reservationTo"
          showTime={true}
          locales={resolveBrowserLocale()}
          options={{ timeZone: "utc" }}
        />
        <FunctionField
          label="app.parking.user"
          render={(record: IReservation) =>
            record.user?.firstName &&
            `${record.user.firstName} ${record.user.lastName[0]}.`
          }
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

export default ReservationsList;
