import { useEffect, useMemo, useState } from "react";
import {
  Create,
  ListToolbarProps,
  ReferenceInput,
  SimpleForm,
  useAuthenticated,
  useLocaleState,
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, CircularProgress, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FileRejection } from "react-dropzone";
import { AxiosError } from "axios";
import {
  StyledAutocompleteInput,
  StyledFileInput,
  StyledSaveButton,
} from "../../../themes/styles";
import { ITicketAttribute } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import CustomAttachmentField from "../../../components/forms/CustomAttachmentField";
import { helpdeskTicketsService } from "../../../api/HelpdeskTicketsService";
import { IBasicResponse } from "../../../types/interfaces/response/basic-response.interface";
import AttributesInputArray from "../../../components/resources/helpdesk/tickets/AttributesInputArray";
import { helpdeskAttributesService } from "../../../api/HelpdeskAttributesService";
import { EResource, getResourceUrl } from "../../../utils/resourcesHelper";
import { uploadedAttachmentFormattedRejectionReason } from "../../../utils/ticket/ticketsHelper";
import useHelpdeskSettings from "../../../hooks/useHelpdeskSettings";
import { getTranslatedFileInputPlaceholder } from "../../../utils/languageService";
import { Roles } from "../../../types/enums/roles";
import { ITicketAccess } from "../../../types/interfaces/helpdesk/ticket/ticket.interface";
import { OrganizationManager } from "../../../constants/OrganizationManager";

export const LoaderContainer = styled(Box)(() => ({
  textAlign: "center",
  width: "100%",
}));

export const FormTitle = styled(Box)(() => ({
  padding: "20px 0",
  fontSize: "16px",
  fontWeight: "bold",
}));

export const FormInnerContainer = styled(Box)(() => ({
  width: "80%",
  maxWidth: "500px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const AdminOptionsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "100%",
}));

const TicketsCreateFormToolbar = (props: ListToolbarProps) => {
  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between" }}
      {...props}
    >
      <StyledSaveButton />
    </Toolbar>
  );
};

const TicketsCreate = () => {
  useAuthenticated();
  const { permissions, isLoading: permissionsLoading } =
    usePermissions<string[]>();
  const [currentLocale] = useLocaleState();
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { settings } = useHelpdeskSettings();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<ITicketAttribute[]>([]);
  const [uploadedAttachmentIds, setUploadedAttachmentIds] = useState<
    { attachmentId: string; name: string }[]
  >([]);
  const [helpdeskTicketsAccess, setHelpdeskTicketsAccess] =
    useState<ITicketAccess | null>(null);

  const ticketsUrl = getResourceUrl(EResource.TICKETS);

  const fetchTicketAttributes = async () => {
    setIsLoading(true);
    try {
      const { data } =
        await helpdeskAttributesService.getAttributes<
          IBasicResponse<ITicketAttribute[]>
        >();

      data.sort(
        (a: ITicketAttribute, b: ITicketAttribute) => a.position - b.position,
      );

      setAttributes(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const convertAttachmentsDataToAcceptedFormat = () => {
    return uploadedAttachmentIds.map((item) => {
      return { attachmentId: item.attachmentId };
    });
  };

  const save = async (data: any) => {
    const ticketRequestData = {
      attributes: data.attributes,
      attachments: convertAttachmentsDataToAcceptedFormat(),
      assigneeId: data.assigneeId,
      workflowId: data.workflowId,
    };

    try {
      if (permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN)) {
        await helpdeskTicketsService.adminCreateTicket(ticketRequestData);
      } else {
        await helpdeskTicketsService.createTicket(ticketRequestData);
      }

      redirect("list", ticketsUrl);
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 409) {
        notify("app.helpdesk.ticketsPage.noWorkflowAvailableParams", {
          type: "error",
        });
        return;
      }
      notify(
        e?.translatedMessage || translate("app.notifications.error.undefined"),
        { type: "error" },
      );
    }
  };

  const uploadFiles = async (files: File[]) => {
    for (const fileItem of files) {
      const formData = new FormData();
      formData.append("attachment", fileItem);

      try {
        const { data } =
          await helpdeskTicketsService.uploadTemporaryAttachment<
            IBasicResponse<{ attachmentId: string }>
          >(formData);

        const uploadedAttachment = {
          attachmentId: data.attachmentId,
          name: fileItem.name,
        };
        setUploadedAttachmentIds((files) => files.concat([uploadedAttachment]));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFileRejections = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const message =
        uploadedAttachmentFormattedRejectionReason(fileRejections);

      notify(message, { type: "error" });
    }
  };

  const deleteUploadedFile = (file: any) => {
    setUploadedAttachmentIds((attachments) =>
      attachments.filter((item) => item.name !== file.title),
    );
  };

  useEffect(() => {
    if (!isLoading) {
      if (permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN)) {
        helpdeskTicketsService
          .adminGetTicketsAccess<IBasicResponse<ITicketAccess>>()
          .then((response) => {
            setHelpdeskTicketsAccess(response.data);
          });
      } else {
        helpdeskTicketsService
          .getTicketsAccess<IBasicResponse<ITicketAccess>>()
          .then((response) => {
            setHelpdeskTicketsAccess(response.data);
          });
      }
    }
  }, [permissions, isLoading]);

  useEffect(() => {
    if (helpdeskTicketsAccess?.canCreate === false) {
      const isAdmin = permissions?.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN);
      if (isAdmin) {
        redirect("/helpdesk/admin/group/tickets");
      } else {
        redirect("/helpdesk/group/tickets");
      }
    }
  }, [helpdeskTicketsAccess, permissions, redirect, ticketsUrl]);

  useEffect(() => {
    fetchTicketAttributes();

    const orgFn = (): void => {
      fetchTicketAttributes();
    };

    OrganizationManager.getInstance().onOrganizationChange(orgFn);

    return (): void => {
      OrganizationManager.getInstance().removeOrganizationChangeListener(orgFn);
    };
  }, []);

  return (
    <Create redirect="list">
      <SimpleForm
        toolbar={<TicketsCreateFormToolbar />}
        onSubmit={save}
        style={{ display: "flex", alignItems: "center" }}
      >
        <FormTitle>
          {translate("app.helpdesk.ticketsPage.createTicketTitle")}
        </FormTitle>
        <FormInnerContainer>
          {isLoading ? (
            <LoaderContainer>
              <CircularProgress size={100} />
            </LoaderContainer>
          ) : (
            <AttributesInputArray inputsData={attributes} />
          )}
        </FormInnerContainer>
        <StyledFileInput
          defaultValue={[]}
          source="attachments"
          label="Attachments"
          accept={settings.attachments.acceptTypes}
          multiple={true}
          maxSize={settings.attachments.limits.maxSize}
          placeholder={
            <p>
              {getTranslatedFileInputPlaceholder(
                settings.attachments.limits.maxSize / 1000000,
              )}
            </p>
          }
          removeIcon={DeleteIcon}
          onRemove={(file: File) => deleteUploadedFile(file)}
          options={{
            onDrop: (files: File[]) => uploadFiles(files),
            onDropRejected: (fileRejections: FileRejection[]) =>
              handleFileRejections(fileRejections),
          }}
        >
          <CustomAttachmentField />
        </StyledFileInput>
        {!permissionsLoading &&
          permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN) && (
            <AdminOptionsContainer>
              <ReferenceInput
                source="assigneeId"
                reference="admin/group/users"
                filter={{ roles: Roles.ROLE_MODULE_HELPDESK_ASSIGNEE }}
                sort={{ field: "name", order: "ASC" }}
              >
                <StyledAutocompleteInput
                  optionText={(record) =>
                    `${record.firstName} ${record.lastName}`
                  }
                  optionValue="id"
                  label="app.helpdesk.assign_user"
                  variant="outlined"
                  filterToQuery={(searchText: string) => ({
                    search: searchText,
                  })}
                  sx={{ minWidth: 210 }}
                />
              </ReferenceInput>
              <ReferenceInput
                source="workflowId"
                reference="helpdesk/admin/group/workflows"
                filter={{ active: "all" }}
              >
                <StyledAutocompleteInput
                  optionText={(record) =>
                    record.name[currentLocale] || record.name["en"]
                  }
                  optionValue="id"
                  label="app.helpdesk.assign_workflow"
                  variant="outlined"
                  filterToQuery={(searchText: string) => ({
                    search: searchText,
                  })}
                  sx={{ minWidth: 210 }}
                />
              </ReferenceInput>
            </AdminOptionsContainer>
          )}
      </SimpleForm>
    </Create>
  );
};
export default TicketsCreate;
