import React from "react";
import { useLocaleState } from "react-admin";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ITicketAttribute } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { baseInputTypeComponentMap } from "../../../../utils/attributes/baseInputTypeComponentMap";
import { helpdeskInputTypeComponentMap } from "../../../../utils/attributes/helpdesk/helpdeskInputTypeComponentMap";
import { rampInputTypeComponentMap } from "../../../../utils/attributes/delivery/rampInputTypeComponentMap";
import AttributeInput from "./AttributeInput";

type TAttributesFieldArrayType = {
  inputsData: ITicketAttribute[];
  dataPrefix?: string;
  inputComponentsMapKey?: string;
};

const FormInputWrapper = styled(Box)(() => ({
  width: "100%",
  margin: "10px 0",
}));

const inputComponentsMaps: Record<string, any> = {
  DeliveryZone_Ramp: rampInputTypeComponentMap,
  Helpdesk: helpdeskInputTypeComponentMap,
};

const AttributesInputArray = ({
  inputsData,
  dataPrefix,
  inputComponentsMapKey = "Helpdesk",
}: TAttributesFieldArrayType) => {
  const [locale] = useLocaleState();

  if (dataPrefix) {
    dataPrefix += ".";
  } else {
    dataPrefix = "";
  }

  const inputComponentsMap =
    inputComponentsMaps[inputComponentsMapKey] || baseInputTypeComponentMap;

  const filteredInputsData = inputsData.filter((item) => {
    if (!item) return false;

    return !!inputComponentsMap[item.type];
  });

  return (
    <>
      {filteredInputsData.map((item: ITicketAttribute, index: number) => {
        return (
          <FormInputWrapper key={dataPrefix + `attribute${index}`}>
            <AttributeInput
              id={item.id}
              source={`${dataPrefix}attributes[${index}]`}
              inputType={item.type}
              label={item.name[locale as keyof typeof item.name]}
              choices={item?.options}
              isRequired={!item.isOptional}
              isSavingNewValues={item?.isSavingNewValues}
              inputComponentsMapKey={inputComponentsMapKey}
            />
          </FormInputWrapper>
        );
      })}
    </>
  );
};

export default AttributesInputArray;
