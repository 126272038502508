import {
  required,
  useAuthenticated,
  useRedirect,
  useResourceDefinition,
  useTranslate,
} from "ra-core";
import { Create, SimpleForm } from "react-admin";
import { Typography } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { ReactElement } from "react";
import { StyledSelect, StyledTextInput } from "../../../themes/styles";
import { ConfigManager } from "../../../constants/ConfigManager";
import { apiRequest } from "../../../api/client";
import { ETaskType } from "../../../types/enums/schedule.enums";
import { IScheduleResourceOptions } from "../../../types/interfaces/schedule.interfaces";
import { OrganizationManager } from "../../../constants/OrganizationManager";
import useHandlers from "./hooks/useHandlers";
import useTaskType from "./hooks/useTaskType";
import TimePlannerInput from "./TimePlannerInput";
import HandlerComponent from "./HandlerComponent";

const ScheduleCreate = (): ReactElement => {
  useAuthenticated();
  const translate = useTranslate();
  const redirect = useRedirect();
  const { options } = useResourceDefinition<IScheduleResourceOptions>();

  const { handlers } = useHandlers({ module: options?.module });
  const { taskTypes } = useTaskType();

  const statusChoices = [
    { id: "active", name: "app.schedule.list.statuses.active" },
    { id: "inactive", name: "app.schedule.list.statuses.inactive" },
  ];

  const createTask = async (data: Record<string, any>): Promise<void> => {
    data = {
      name: data.name,
      description: data.description ? data.description : undefined,
      active: data.active,
      type: data.type,
      ...(data.type === ETaskType.OneTime &&
        data.oneTime?.date && {
          oneTime: {
            date: data.oneTime.date,
          },
        }),
      ...(data.type === ETaskType.Recurring &&
        data.recurring?.cron && {
          recurring: {
            cron: data.recurring.cron,
          },
        }),
      timeZone: data.timeZone,
      handler: data.handler,
      payload: data.payload,
    };

    try {
      const orgId = OrganizationManager.getInstance().getOrgId();
      const config: AxiosRequestConfig = {
        method: "POST",
        url: `${ConfigManager.getInstance().getApiUrl()}/api/v1/${options?.module}/admin/${orgId}/schedule/tasks`,
        data,
      };
      await apiRequest(config);
      redirect("list", `/${options?.module}/admin/group/schedule/tasks`);
    } catch (e) {
      console.log(e, "createTask");
      return e;
    }
  };

  return (
    <Create redirect="list">
      <SimpleForm onSubmit={createTask}>
        <Typography variant="h6" gutterBottom>
          {translate("app.schedule.form.sections.general")}
        </Typography>
        <StyledTextInput
          source="name"
          variant="outlined"
          label={translate("app.schedule.form.fields.name")}
          validate={[required()]}
        />
        <StyledTextInput
          source="description"
          variant="outlined"
          label={translate("app.schedule.form.fields.description")}
          multiline
        />
        <StyledSelect
          source="active"
          variant="outlined"
          label={translate("app.schedule.list.status")}
          choices={statusChoices}
          defaultValue={"inactive"}
          validate={[required()]}
        />

        <Typography variant="h6" gutterBottom>
          {translate("app.schedule.form.sections.time")}
        </Typography>
        <StyledSelect
          source="type"
          variant="outlined"
          label={translate("app.schedule.form.fields.type")}
          choices={taskTypes}
          validate={[required()]}
        />
        <TimePlannerInput />

        <Typography variant="h6" gutterBottom>
          {translate("app.schedule.form.sections.action")}
        </Typography>
        <StyledSelect
          source="handler"
          variant="outlined"
          label={translate("app.schedule.form.fields.actionType")}
          choices={handlers}
          validate={[required()]}
        />
        <HandlerComponent handlersMap={options?.handlers || {}} />
      </SimpleForm>
    </Create>
  );
};

export default ScheduleCreate;
